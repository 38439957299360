export const BUSINESS_TYPE = Object.freeze({
    RESTAURANT: 'restaurant',
    FLORIST: 'florist',
    LAUNDRY: 'laundry',
    GROCERY: 'grocery',
    PHARMACY: 'pharmacy',
    ASSISTANT: 'assistant',
    ENTITY: 'entity'
});

export const PAYMENT_TYPE = Object.freeze({
    PRE_PAYMENT: 1,
    POST_PAYMENT: 2,
    PRE_POST_PAYMENT: 3
});

export const ORDER_TYPE = Object.freeze({
    RESTAURANT_DELIVERY: 1,
    FLOWERS_DELIVERY: 2,
    LAUNDRY_DELIVERY: 3,
    GROCER_DELIVERY: 4,
    PHARMACY_DELIVERY: 5,
    ASSISTANT_HELP: 6,
    ENTITY_DELIVERY: 7,
});

export const DELIVERY_TYPE = Object.freeze({
    DOOR: 1,
    MEETING_POINT: 2,
    WITHOUT: 3,
    COLLECT: 4,
});

export const DELIVERY_TYPE_TITLE = Object.freeze({
    [DELIVERY_TYPE.DOOR]: 'Доставка до двери',
    [DELIVERY_TYPE.MEETING_POINT]: 'Доставка до точки встречи',
    [DELIVERY_TYPE.WITHOUT]: 'Самовывоз',
    [DELIVERY_TYPE.COLLECT]: 'Сборка заказа консьержем',
});

export const ENTITY_TYPE = Object.freeze({
    [ORDER_TYPE.RESTAURANT_DELIVERY]: 'restaurant',
    [ORDER_TYPE.FLOWERS_DELIVERY]: 'florist',
    [ORDER_TYPE.LAUNDRY_DELIVERY]: 'laundry',
    [ORDER_TYPE.GROCER_DELIVERY]: 'grocer',
    [ORDER_TYPE.PHARMACY_DELIVERY]: 'pharmacy',
    [ORDER_TYPE.ASSISTANT_HELP]: 'assistant',
    [ORDER_TYPE.ENTITY_DELIVERY]: 'entity',
});

export const PRODUCT_TYPE = Object.freeze({
    [ORDER_TYPE.RESTAURANT_DELIVERY]: 'dish',
    [ORDER_TYPE.FLOWERS_DELIVERY]: 'bouquet',
    [ORDER_TYPE.LAUNDRY_DELIVERY]: 'laundry_service',
    [ORDER_TYPE.GROCER_DELIVERY]: 'goods',
    [ORDER_TYPE.PHARMACY_DELIVERY]: 'drug',
    [ORDER_TYPE.ASSISTANT_HELP]: 'assistant_service',
    [ORDER_TYPE.ENTITY_DELIVERY]: 'entity_product',
});

export const ORDER_TYPE_SHORT = Object.freeze({
    [ORDER_TYPE.RESTAURANT_DELIVERY]: 'rd',
    [ORDER_TYPE.FLOWERS_DELIVERY]: 'fd',
    [ORDER_TYPE.LAUNDRY_DELIVERY]: 'ld',
    [ORDER_TYPE.GROCER_DELIVERY]: 'gd',
    [ORDER_TYPE.PHARMACY_DELIVERY]: 'pd',
    [ORDER_TYPE.ASSISTANT_HELP]: 'ah',
    [ORDER_TYPE.ENTITY_DELIVERY]: 'ed',
});

export const BUSINESS_TYPE_MAP = Object.freeze({
    [ORDER_TYPE.RESTAURANT_DELIVERY]: BUSINESS_TYPE.RESTAURANT,
    [ORDER_TYPE.FLOWERS_DELIVERY]: BUSINESS_TYPE.FLORIST,
    [ORDER_TYPE.LAUNDRY_DELIVERY]: BUSINESS_TYPE.LAUNDRY,
    [ORDER_TYPE.GROCER_DELIVERY]: BUSINESS_TYPE.GROCERY,
    [ORDER_TYPE.PHARMACY_DELIVERY]: BUSINESS_TYPE.PHARMACY,
    [ORDER_TYPE.ASSISTANT_HELP]: BUSINESS_TYPE.ASSISTANT,
    [ORDER_TYPE.ENTITY_DELIVERY]: BUSINESS_TYPE.ENTITY
});

export const CART_COMPARISON_KEYS = [
    'id',
    'ingredientGroups',
    'modifierGroups',
    'modifiers',
    'ingredients',
    'options',
    'extras',
    'entityVariants',
    'entityOptions'
];

export const CARD_TYPE = Object.freeze({
    VISA: 1,
    MASTER_CARD: 2,
    MAESTRO: 3,
    MIR: 4,
    SBP: 5,
});

export const CARD_TYPE_MAP = Object.freeze({
    [CARD_TYPE.VISA]: 'visa',
    [CARD_TYPE.MASTER_CARD]: 'master-card',
    [CARD_TYPE.MAESTRO]: 'maestro',
    [CARD_TYPE.MIR]: 'mir'
});

export const ORDER_STATUS = Object.freeze({
    // Оформление - для предоплаты
    // Ожидает подтверждения - для постоплаты
    SUBMITTED: 1,

    // В работе - для постоплаты (химчистки)
    IN_WORK: 12,

    // Ожидает оплаты - для постоплаты
    PREPARED: 11,

    // Оплачен
    PAID: 2,

    // Принят партнером
    ACCEPTED: 22,

    // Готовим (рестораны)
    // Чистим (химчистки)
    // Собираем (цветы, магазины, аптеки)
    AWAITING: 3,
    // Приготовлен
    AWAITING_READY: 13,

    // Доставляется курьером
    DELIVERY: 4,
    // Доставляется консьержем
    DELIVERY_CONCIERGE: 14,
    MEETING_POINT: 24,

    // Выполнен
    DONE: 5,
    // Корректировка чека
    PAYMENT_CORRECTION: 15,
    // Достален до двери
    DELIVERED_DOOR: 25,

    // Отменен
    CANCELED: 6,
});

export const ORDER_STATUS_DESCRIPTION = {
    [ORDER_STATUS.SUBMITTED]: {
        label: 'Оформление',
        color: '#D6D3AD'
    },
    [ORDER_STATUS.IN_WORK]: {
        label: 'В работе',
        color: '#498AEE'
    },
    [ORDER_STATUS.PREPARED]: {
        label: 'Ожидает оплаты',
        color: '#E4CD75'
    },
    [ORDER_STATUS.PAID]: {
        label: 'Оплачено',
        color: '#E26B64'
    },
    [ORDER_STATUS.ACCEPTED]: {
        label: 'Оплачено',
        color: '#E26B64'
    },
    [ORDER_STATUS.AWAITING]: {
        label: 'Готовится',
        color: '#6A9BE6'
    },
    [ORDER_STATUS.AWAITING_READY]: {
        label: 'Приготовлен',
        color: '#3D83EB'
    },
    [ORDER_STATUS.DELIVERY]: {
        label: 'Доставка',
        color: '#48A7C5'
    },
    [ORDER_STATUS.DELIVERY_CONCIERGE]: {
        label: 'Доставка',
        color: '#6DCFD5'
    },
    [ORDER_STATUS.MEETING_POINT]: {
        label: 'В точке встречи',
        color: '#58ADA0'
    },
    [ORDER_STATUS.DONE]: {
        label: 'Выполнен',
        color: '#97CE98'
    },
    [ORDER_STATUS.PAYMENT_CORRECTION]: {
        label: 'Выполнен',
        color: '#97CE98'
    },
    [ORDER_STATUS.CANCELED]: {
        label: 'Отменен',
        color: '#3c4b64'
    },
    [ORDER_STATUS.DELIVERED_DOOR]: {
        label: 'Доставлен до двери',
        color: '#97CE98',
    }
}

// order types
export const RD_ORDER_TYPE = 1
export const FD_ORDER_TYPE = 2
export const LD_ORDER_TYPE = 3
export const GD_ORDER_TYPE = 4
export const PD_ORDER_TYPE = 5
export const AH_ORDER_TYPE = 6
export const ED_ORDER_TYPE = 7

export const PAYMENT_ENDPOINTS = {
    VALIDATE: 'validate',
    PRE: 'pre',
    PAY: 'pay'
}

export const ORDER_PAYMENT_STATUS = {
    PROCESSING: 1,
    SUCCESS: 2,
    FAIL: 3,
    CANCELLED: 4
};

export const DAYS = {
    MONDAY: 0,
    TUESDAY: 1,
    WEDNESDAY: 2,
    THURSDAY: 3,
    FRIDAY: 4,
    SATURDAY: 5,
    SUNDAY: 6
}

export const WEEK_DAYS = {
    [DAYS.MONDAY]: {
        SHORT: 'пон',
        FULL: 'понедельник',
        WHEN: 'в понедельник',
    },
    [DAYS.TUESDAY]: {
        SHORT: 'вт',
        FULL: 'вторник',
        WHEN: 'во вторник',
    },
    [DAYS.WEDNESDAY]: {
        SHORT: 'ср',
        FULL: 'среду',
        WHEN: 'в среду',
    },
    [DAYS.THURSDAY]: {
        SHORT: 'чт',
        FULL: 'четверг',
        WHEN: 'в четверг',
    },
    [DAYS.FRIDAY]: {
        SHORT: 'пт',
        FULL: 'пятницу',
        WHEN: 'в пятницу',
    },
    [DAYS.SATURDAY]: {
        SHORT: 'cуб',
        FULL: 'субботу',
        WHEN: 'в субботу',
    },
    [DAYS.SUNDAY]: {
        SHORT: 'вс',
        FULL: 'воскресенье',
        WHEN: 'в воскресенье',
    },
}

export const SERVICE_TYPE_TO_HUMAN_WORDS = {
    [ORDER_TYPE.LAUNDRY_DELIVERY]: {
        AT_SINGLE: 'химчистке',
        AT_PLURAL: 'химчистках'
    },
    [ORDER_TYPE.GROCER_DELIVERY]: {
        AT_SINGLE: 'магазине',
        AT_PLURAL: 'магазинах'
    },
    [ORDER_TYPE.RESTAURANT_DELIVERY]: {
        AT_SINGLE: 'ресторане',
        AT_PLURAL: 'ресторанах'
    },
    [ORDER_TYPE.FLOWERS_DELIVERY]: {
        AT_SINGLE: 'цветочном магазине',
        AT_PLURAL: 'цветочных магазинах'
    },
    [ORDER_TYPE.PHARMACY_DELIVERY]: {
        AT_SINGLE: 'аптеке',
        AT_PLURAL: 'аптеках'
    },
    [ORDER_TYPE.ASSISTANT_HELP]: {
        AT_SINGLE: 'помощнике',
        AT_PLURAL: 'помощниках'
    },
    [ORDER_TYPE.ENTITY_DELIVERY]: {
        AT_SINGLE: 'сервисе',
        AT_PLURAL: 'сервисах'
    },
}


export const PUSH_TYPE = Object.freeze({
    LINK: 'link',
    ORDER_STATUS: 'order_status',
});

export const CUSTOMER_LOG_TYPE = Object.freeze({
    FIRST_OPEN: 1,
    FIRST_LOGIN: 2,
});

export const TIPS_BUTTONS = Object.freeze({
    WITHOUT: 0,
    FIFTY: 1,
    HUNDRED: 2,
    ANOTHER_SUM: 3,
    CANCELE: 4,
    ANOTHER_SUM_APPROVE: 5,
});

export const servicesDefaultColorClassMap = Object.freeze({
    [BUSINESS_TYPE.RESTAURANT]: '#1f3a60',
    [BUSINESS_TYPE.GROCERY]: '#1f3a60',
    [BUSINESS_TYPE.FLORIST]: '#C1BECF',
    [BUSINESS_TYPE.PHARMACY]: '#E8D9C0',
    [BUSINESS_TYPE.LAUNDRY]: '#B6C7DA',
    [BUSINESS_TYPE.ASSISTANT]: '#a9aec9',
});

export const CssVarsEnum = Object.freeze({
    ENTITY_BG_COLOR: '--entity-bg-color',
    ENTITY_COLOR: '--entity-color'
});

export const PRESETS = Object.freeze({
    XL: 0,
    LG: 1,
    MD: 2,
    SM: 3,
    THUMB: 4,
    SQ: 5,
});

export const PRESETS_SYMBOLS = Object.freeze({
    [PRESETS.XL]: 'xl',
    [PRESETS.LG]: 'lg',
    [PRESETS.MD]: 'md',
    [PRESETS.SM]: 'sm',
    [PRESETS.THUMB]: 'thumb',
    [PRESETS.SQ]: 'sq',
});

export const UNITS = Object.freeze({
    KILOGRAM: 0,
    GRAM: 1,
    LITER: 2,
    MILLILITER: 3,
});

export const UNITS_ENUM = Object.freeze({
    [UNITS.KILOGRAM]: 'кл',
    [UNITS.GRAM]: 'г',
    [UNITS.LITER]: 'л',
    [UNITS.MILLILITER]: 'мл',
});

export const UNIT_CODE = Object.freeze({
    UNIT: 0,
    GRAM: 10,
    KILOGRAM: 11,
    NONE: 255
});

export const UNIT_CODE_TITLE = Object.freeze({
    [UNIT_CODE.UNIT]: 'шт',
    [UNIT_CODE.GRAM]: 'г',
    [UNIT_CODE.KILOGRAM]: 'кг',
    [UNIT_CODE.NONE]: '',
});

export const STATUS_DIALOGS_TYPES = Object.freeze({ 
    NOT_PAID: 1, // Не оплачен
    AWAITING: 2, // Собираем
    DELIVERY: 3, // В доставке (консьерж в пути)
    DONE: 4, // Выполнен,
    CANCELED: 5, // Отменен
    AWAITING_READY: 6, // готов к выдаче (в ресторане, в магазине, в точке встречи)
    MEETING_POINT: 7, // в точке встречи
    IN_WORK: 8, // в работе
    NOT_PAID_POST: 9, // не оплачен постоплата
    COOKING: 10, // готовим
    COMPLETED: 11, // уже у вас
    DELIVERED_DOOR: 12 // доставлен до двери
});

export const CACHE_TIME = 30*60*1000;