import axios from '../../plugins/axios';
import { sendAppMetricaEvent } from '../../js/utils/service-helpers';

export default {
    namespaced: true,
    state: {
        request: null,
        restaurants: {
            entities: [],
            products: []
        },
        grocers: {
            entities: [],
            products: []
        },
        entities: {
            entities: [],
            products: []
        },
        products: []
    },

    mutations: {
        SET_PRODUCTS(state, payload) {
            state.products = payload;
        },
        SET_RESTAURANTS(state, payload) {
            state.restaurants.entities = payload?.entities ? payload.entities : [];
            state.restaurants.products = payload?.products ? payload.products.slice().sort((a, b) => a.restaurant_id - b.restaurant_id) : [];
        },
        SET_GROCERS(state, payload) {
            state.grocers.entities = payload?.entities ? payload.entities : [];
            state.grocers.products = payload?.products ? payload.products.slice().sort((a, b) => a.grocer_id - b.grocer_id) : [];
        },
        SET_ENTITIES(state, payload) {
            state.entities.entities = payload?.entities ? payload.entities : [];
            state.entities.products = payload?.products ? payload.products : [];
        },
        CLEAR(state) {
            state.products = [];
            state.restaurants = {
                entities: [],
                products: []
            };
            state.grocers = {
                entities: [],
                products: []
            };
            state.entities = {
                entities: [],
                products: []
            };
        },
    },
    actions: {
        clear({ state, commit } = {}) {
            if(state.request) {
                state.request.abort();
            }
            commit('CLEAR')
        },
        async fetch({ state, commit }, { entityType = null, entityId = null, search }) {
            if(state.request) {
                state.request.abort();
            }
            sendAppMetricaEvent('SEARCH_REQUEST', {
                PageType: entityType,
                Search_level: getSearchLevel({ entityType, entityId }),
                Page: '',
                Request: search
            });
            state.request = new AbortController();
            const response = await axios.post('/front/search-elastic', { entityType, entityId, search }, { signal: state.request.signal });
            state.request = null;
            if(response?.data) {
                const eCount = entitiesCount(response?.data) || 0;
                const pCount = productsCount(response?.data) || 0;

                sendAppMetricaEvent('SEARCH_RESULT', {
                    PageType: entityType,
                    Page: '',
                    Request: search,
                    Count: eCount || 0,
                    CountSearch: eCount,
                    CountAll: eCount + pCount || 0,
                    Search_level: getSearchLevel({ entityType, entityId }),
                });
            }
            commit('SET_RESTAURANTS', response?.data?.restaurants);
            commit('SET_GROCERS', response?.data?.grocers);
            commit('SET_ENTITIES', response?.data?.entities);
        },
        async fetchInEntityList({ state, commit }, { search, entityType }) {
            if(state.request) {
                state.request.abort();
            }
            sendAppMetricaEvent('SEARCH_REQUEST', {
                PageType: entityType,
                Search_level: getSearchLevel({ entityType }),
                Page: entityType,
                Request: search
            });
            state.request = new AbortController();
            const response = await axios.post('/front/search-elastic', { entityType, search }, { signal: state.request.signal });
            state.request = null;
            if(response?.data) {
                const eCount = entitiesCount(response?.data) || 0;
                const pCount = productsCount(response?.data) || 0;
                sendAppMetricaEvent('SEARCH_RESULT', {
                    PageType: entityType,
                    Page: entityType,
                    Request: search,
                    Count: eCount || 0,
                    CountSearch: eCount,
                    CountAll: eCount + pCount || 0,
                    Search_level: getSearchLevel({ entityType }),
                });
            }
            commit('SET_RESTAURANTS', response?.data?.restaurants);
            commit('SET_GROCERS', response?.data?.grocers);
            commit('SET_ENTITIES', response?.data?.entities);
        },
        async fetchInEntity({ state, commit }, { search, entityType, entityCode, entityId }) {
            if(state.request) {
                state.request.abort();
            }
            sendAppMetricaEvent('SEARCH_REQUEST', {
                PageType: entityType,
                Page: entityCode,
                Request: search,
                Search_level: getSearchLevel({ entityType, entityId }),
            });
            state.request = new AbortController();
            const response = await axios.post('/front/search-elastic', { entityType, entityId, search }, { signal: state.request.signal });
            state.request = null;
            if(response?.data) {
                sendAppMetricaEvent('SEARCH_RESULT', {
                    PageType: entityType,
                    Page: entityCode,
                    Request: search,
                    Count: response?.data?.length ? 1 : 0,
                    CountSearch: response?.data?.length || 0,
                    CountAll: response?.data.length,
                    Search_level: getSearchLevel({ entityType, entityId }),
                });
            }
            commit('SET_PRODUCTS', response?.data);
        },
    },
}

const entitiesCount = data => {
    let count = 0;
    if(data?.restaurants) {
        count += data?.restaurants?.entities?.length || 0;
        const ids = data?.restaurants?.products?.map(item => item.restaurant_id) || [];
        const unique = ids?.filter((v, i, a) => a.indexOf(v) === i) || [];
        count += unique.length || 0;
    }
    if(data?.grocers) {
        count += data?.grocers?.entities?.length || 0;
        const ids = data?.grocers?.products?.map(item => item.grocer_id) || [];
        const unique = ids?.filter((v, i, a) => a.indexOf(v) === i) || [];
        count += unique.length || 0;
    }
    if(data?.entities) {
        count += data?.entities?.entities?.length || 0;
        const ids = data?.entities?.products?.map(item => item.entity_id) || [];
        const unique = ids?.filter((v, i, a) => a.indexOf(v) === i) || [];
        count += unique.length || 0;
    }
    return count;
}
const productsCount = data => {
    let count = 0;
    if(data?.restaurants) {
        count += data?.restaurants?.products?.length || 0;
    }
    if(data?.grocers) {
        count += data?.grocers?.products?.length || 0;
    }
    if(data?.entities) {
        count += data?.entities?.products?.length || 0;
    }
    return count;
}

const getSearchLevel = ({ entityType = null, entityId = null }) => {
    if (entityType && !entityId) return 'Category Search';
    if (entityType && entityId) return 'Partner Search';
    return 'Main Search';
}
