<template>
    <f7-sheet
        class="error-message-sheet-fullscreen"
    >
        <div>
            <div class="error-message-sheet-fullscreen__title">
                Неполадки с интернет-соединением
            </div>
            <div class="error-message-sheet-fullscreen__img"></div>
            <div class="error-message-sheet-fullscreen__text">
                Пожауйста, проверьте интернет-соединение и попробуйте обновить страницу
            </div>
            <div class="error-message-sheet-fullscreen__info" v-html="info"></div>
        </div>
        <div>
            <button
                :class="`custom-button error-message-sheet-fullscreen__button-submit`"
                @click.stop="close"
            >
                Обновить страницу
            </button>
        </div>
    </f7-sheet>
</template>

<script>
    import { bus } from '../../js/utils/bus';
    const { version } = require('../../../package.json');

    export default {
        name: 'ErrorMessageSheetFullscreen',
        data: () => ({
            shown: false,
            error: '',
            request: ''
        }),
        computed: {
            info() {
                return `${ version }<br>${ this.request } ${ this.error }`
            }
        },
        mounted() {
            bus.$on('ErrorMessageSheetFullscreenShow', async ({ error, request }) => {
                this.error = error;
                this.request = request;
                this.$f7.sheet.open('.error-message-sheet-fullscreen');
            });
        },
        methods: {
            goHome() {
                this.$f7.views[0].router.navigate({ name: 'home' });
                this.$f7.sheet.close();
            },
            close() {
                window.location.reload();
                this.$f7.sheet.close();
            }
        },
    };
</script>

<style lang="scss">
.error-message-sheet-fullscreen {
    height: 100vh;

    .sheet-modal-inner {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        padding: 30px 20px 20px !important;
    }

    &__img {
        height: 180px;
        background: url('../../static/errorPage.png') center center no-repeat;
        background-size: contain;
        margin: 0 -20px 45px;
    }
    &__title {
        margin: 0 0 45px;
        font-weight: 600;
        font-size: 20px;
        line-height: 23px;
        text-align: center;
        color: #000000;
    }
    &__text {
        margin: 0 0 45px;
        font-weight: 400;
        font-size: 14px;
        line-height: 150%;
        text-align: center;
        color: #000000;
    }
    &__info {
        margin: 0 0 45px;
        font-style: italic;
        font-weight: 400;
        font-size: 14px;
        line-height: 150%;
        text-align: center;
        color: #8EA0BA;
    }
    &__buttons {
        display: flex;
        justify-content: space-between;
        > button {
            width: 48%;
        }
    }
    &__button-submit {
        background: #8EA0BB;
        color: #FFFFFF;
        border: 1px solid #8EA0BB;
        border-radius: 10px;
        margin-bottom: 12px;

        &__reverse {
            background: #FFFFFF;
            color: #8EA0BB;
        }
    }
}
</style>
