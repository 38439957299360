<template>
    <div>
        <OnboardingStories
            v-if="showStories"
            :onboarding="onboarding"
            @stories-end="closeStories"
        />

        <f7-sheet
            :opened="shown"
            class="onboarding-sheet"
            swipe-to-close
            @sheet:closed="closeModal()"
        >
            <template v-if="onboarding && onboarding.id">
                <div>
                    <div class="onboarding-sheet__header-banner">
                        <div class="onboarding-sheet__swipe-line-banner"></div>
                    </div>
                    <div class="onboarding-sheet__banner_picture">
                        <template>
                            <video
                                playsinline
                                autoplay
                                muted
                                loop
                                width="100%"
                                :poster="imageSrc(onboarding.image)"
                                @click="openStories"
                            >
                                <source :src="imageSrc(onboarding.image)" type="video/mp4" />
                            </video>
                        </template>
                    </div>
                </div>
            </template>
        </f7-sheet>
    </div>
</template>

<script>
    import { mapState } from 'vuex';
    import indexedDB from '@/plugins/indexedDB';
    import OnboardingStories from '@/components/common/OnboardingStories';

    export default {
        name: 'Onboarding',
        components: {
            OnboardingStories,
        },
        data() {
            return {
                shown: false,
                onboarding: null,
                showStories: false,
            };
        },
        computed: {
            ...mapState('onboarding', ['onboardings']),
        },
        async created() {
            this.shown = false;

            await this.$store.dispatch('onboarding/fetchOnboardings');

            this.$root.$on('onboardingShow', async ({ routeName = '/' }) => {  
                // console.log(new Date().getMilliseconds(), '3');
                
                this.onboarding = null;

                const localOnboardings = await indexedDB.get('mircity-onboardings') || {};
                
                const filteredOnboardings = this.onboardings.filter(item => item.router === routeName);
                if (filteredOnboardings?.length) {
                    let index = 0;
                    while (!this.onboarding && index < filteredOnboardings.length) {
                        const onboarding = filteredOnboardings[index];
                        if(!localOnboardings[onboarding.id]) {
                            localOnboardings[onboarding.id] = { shows: 0 };
                        }
                        if(localOnboardings[onboarding.id].shows < onboarding.limit_show_count) {
                            this.onboarding = onboarding;
                        }
                        index++;
                    }
                }
                
                if (this.onboarding?.id) {
                    if (this.onboarding.show_banner) {
                        this.shown = true;
                    } else {
                        this.openStories();
                    }
                    localOnboardings[this.onboarding.id].shows += 1;
                    await indexedDB.set('mircity-onboardings', localOnboardings);
                }
            });
            this.$root.$on('onboardingClose', async () => {
                this.closeModal();
                this.showStories = false;
            });
        },
        methods: {
            imageSrc(picture) {
                return picture ? `${process.env.VUE_APP_IMAGES_URL}/${picture}` : '';
            },
            openStories() {
                this.showStories = true;
                this.shown = false;
            },
            closeStories() {
                this.showStories = false;
            },
            closeModal() {
                this.shown = false;
                setTimeout(() => {
                    window.dispatchEvent(new Event('resize'));
                }, 300);
            },
        },
    };
</script>

<style lang="scss">
.onboarding-sheet {
    min-height: 100px;
    height: auto;
    bottom: calc(65px + env(safe-area-inset-bottom));
    bottom: calc(65px + constant(safe-area-inset-bottom));
    --f7-sheet-bg-color: #fefefe;
    background-color: #fefefe;

    .sheet-modal-inner {
        padding: 20px !important;
    }

    &__header-banner {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    &__swipe-line-banner {
        width: 40px;
        height: 4px;
        background: rgba(169, 178, 191, 1);
        border-radius: 40px;
        margin-bottom: 10px;
    }

    &__banner_picture {
        position: relative;
        width: 100%;
        overflow: hidden;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        border-radius: 10px;

        video {
            width: 100%;
            height: 100%;
            object-fit: cover;
            cursor: pointer;
        }
    }
}
</style>
