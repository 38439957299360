import axios from '../../plugins/axios-silent';
import indexedDB from '../../plugins/indexedDB';
import { state } from './style';

export default {
    namespaced: true,

    state: {
        banner: {},
        banners: [],
        ads_banners: [],
        banners_list: {},
        popup_banners_list: [],
        selection_carousel_banners_list: [],
        top_banners_profile: [],
        skeletonBanners: [
            {
                id: 1,
                skeleton: true,
            },
            {
                id: 2,
                skeleton: true,
            },
            {
                id: 3,
                skeleton: true,
            },
            {
                id: 4,
                skeleton: true,
            },
            {
                id: 5,
                skeleton: true,
            },
        ]
    },
    mutations: {
        SET_BANNER(state, payload) {
            state.banner = payload;
        },
        SET_BANNERS(state, payload) {
            state.banners = payload;
        },
        SET_BANNERS_LIST(state, payload) {
            state.banners_list[payload.routeName] = payload.result;
        },
        SET_POPUP_BANNERS_LIST(state, payload) {
            state.popup_banners_list = payload;
        },
        SET_SELECTION_CAROUSEL_BANNERS(state, payload) {
            state.selection_carousel_banners_list = payload;
        },
        CLEAR_BANNERS(state, payload) {
            state.banners = [];
        },
        SET_TOP_BANNERS_PROFILE(state, payload) {
            state.top_banners_profile = payload;
        },
        SET_BANNERS_LOCATIONS(state, payload) {
            state.ads_banners = payload;
        }
    },
    actions: {
        clear({ state, commit } = {}) {
            state.banners = [];
        },
        clearList({ state, commit } = {}) {
            state.banners = [];
            state.ads_banners = [];
        },
        async getById({ commit }, { id }) {
            const { data } = await axios.get(`/front/banners/id/${ id }`);
            
            let banner = data;
            const bannerShownCounts = await indexedDB.get('mircity-bannerShownCounts') || {};
            const bannerLastShowns = await indexedDB.get('mircity-bannerLastShowns') || {};
        
            if(banner && banner.shown_delay && bannerLastShowns[banner.id]) {
                if((new Date()).getTime() < bannerLastShowns[banner.id] + (banner.shown_delay * 1000)) {
                    banner = null;
                }
            }
            if (banner && banner.max_shown_count) {
                const shownCount = bannerShownCounts[data.id] || 0;
                if (shownCount < banner.max_shown_count) {
                    bannerShownCounts[data.id] = shownCount + 1;
                    if(banner.shown_delay) {
                        bannerLastShowns[banner.id] = (new Date()).getTime();
                    }
                } else {
                    banner = null;
                }
            }
            commit('SET_BANNER', banner);
            if(banner) {
                await indexedDB.set('mircity-bannerShownCounts', bannerShownCounts);
                await indexedDB.set('mircity-bannerLastShowns', bannerLastShowns);
            }
        },
        async getAdsBannersLocations({ state, commit }, { routeName }) {
            const { data } = await axios.get(`/front/banners/adsLocations/${routeName}`);
            commit('SET_BANNERS_LOCATIONS', data);
        },
        // БАНЕР СВЕРХУ
        async getByRoute({ state, commit }, { routeName }) {
            const { data } = await axios.get(`/front/banners/list/${routeName}`);
            const filteredBanners = await bannersToShow(data);
            commit('SET_BANNERS', filteredBanners);
        },        
        // КАРУСЕЛЬ БАНЕР
        async getByRouteSelectionCarousel({ state, commit }, { routeName }) {
            const { data } = await axios.get(`/front/banners/selectionCarousel/${routeName}`);
            const filteredBanners = await bannersToShow(data);
            commit('SET_SELECTION_CAROUSEL_BANNERS', filteredBanners);
        },
        // ТОП БАНЕР В ПРОФИЛЕ
        async getTopBannerProfile({ state, commit }) {
            const { data } = await axios.get('/front/banners/profile');
            commit('SET_TOP_BANNERS_PROFILE', data);
        },
        async getPopupList({ state, commit }) {
            const { data } = await axios.get('/front/banners/popuplist');
            let result = data;

            const bannerLastShownPlace = await indexedDB.get('mircity-bannerLastShownPlace') || -1;
            const bannerShownCounts = await indexedDB.get('mircity-bannerShownCounts') || {};
            const bannerLastShowns = await indexedDB.get('mircity-bannerLastShowns') || {};

            if(result.length && bannerLastShownPlace) {
                result = result.filter(item => item.place > +bannerLastShownPlace);
            }

            result = result.filter(banner => {
                if(banner.shown_delay && bannerLastShowns[banner.id]) {
                    if((new Date()).getTime() < bannerLastShowns[banner.id] + (banner.shown_delay * 1000)) {
                        return false;
                    }
                }
                const shownCount = bannerShownCounts[banner.id] || 0;
                return !banner.max_shown_count || shownCount < banner.max_shown_count;
            });

            if(!result.length) {
                await indexedDB.remove('mircity-bannerLastShownPlace');
                result = data.filter(banner => {
                    if(banner.shown_delay && bannerLastShowns[banner.id]) {
                        if((new Date()).getTime() < bannerLastShowns[banner.id] + (banner.shown_delay * 1000)) {
                            return false;
                        }
                    }
                    const shownCount = bannerShownCounts[banner.id] || 0;
                    return !banner.max_shown_count || shownCount < banner.max_shown_count;
                });
            }

            if(result.length) {
                const currentBanner = result[0];
                if (currentBanner.max_shown_count) {
                    bannerShownCounts[currentBanner.id] = (bannerShownCounts[currentBanner.id] || 0) + 1;
                }
                if(currentBanner.shown_delay) {
                    bannerLastShowns[currentBanner.id] = (new Date()).getTime();
                }
                await indexedDB.set('mircity-bannerLastShownPlace', currentBanner.place);
                await indexedDB.set('mircity-bannerShownCounts', bannerShownCounts);
                await indexedDB.set('mircity-bannerLastShowns', bannerLastShowns);
            }
            commit('SET_POPUP_BANNERS_LIST', result);
        },

        async getByRouteListPlaces({ state, commit }, { routeName }) {
            const { data } = await axios.get(`/front/banners/list/${routeName}`);
        
            const result = [];
            const placesGroups = {};
            const localData = await indexedDB.get('mircity-banersIndexes') || {};
            const bannerShownCounts = await indexedDB.get('mircity-bannerShownCounts') || {};
            const bannerLastShowns = await indexedDB.get('mircity-bannerLastShowns') || {};

            for (const banner of data.sort((a, b) => (a.place > b.place) ? -1 : 1)) {
                if (+banner.place !== 0) {
                    if (placesGroups[+banner.place]) {
                        placesGroups[+banner.place].push(banner);
                    } else {
                        placesGroups[+banner.place] = [banner];
                    }
                }
            }
        
            const nextLocalData = {};
        
            for (const groupKey in placesGroups) {
                const group = placesGroups[groupKey];
        
                const filteredGroup = group.filter(banner => {
                    if(banner.shown_delay && bannerLastShowns[banner.id]) {
                        if((new Date()).getTime() < bannerLastShowns[banner.id] + (banner.shown_delay * 1000)) {
                            return false;
                        }
                    }
                    const shownCount = bannerShownCounts[banner.id] || 0;
                    return !banner.max_shown_count || shownCount < banner.max_shown_count;
                });
        
                if (filteredGroup.length === 0) continue;
        
                const lastShownId = localData[groupKey] || null;
                const lastShowBannerIndex = lastShownId ? filteredGroup.findIndex(b => b.id === lastShownId) : -1;
                const currentBanner = filteredGroup[+lastShowBannerIndex + 1] || filteredGroup[0];
        
                const maxShownCount = currentBanner.max_shown_count;
                if (maxShownCount !== null && maxShownCount !== 0) {
                    bannerShownCounts[currentBanner.id] = (bannerShownCounts[currentBanner.id] || 0) + 1;
                }
                if(currentBanner.shown_delay) {
                    bannerLastShowns[currentBanner.id] = (new Date()).getTime();
                }
        
                result.push(currentBanner);
                nextLocalData[groupKey] = currentBanner.id;
            }
        
            await indexedDB.set('mircity-bannerShownCounts', bannerShownCounts);
            await indexedDB.set('mircity-bannerLastShowns', bannerLastShowns);
            await indexedDB.set('mircity-banersIndexes', nextLocalData);
        
            commit('SET_BANNERS_LIST', { routeName, result });
        },           
    }
}

const bannersToShow = async banners => {
    const bannerShownCounts = await indexedDB.get('mircity-bannerShownCounts') || {};
    const bannerLastShowns = await indexedDB.get('mircity-bannerLastShowns') || {};

    const filteredBanners = banners.filter(banner => {
        if(banner.shown_delay && bannerLastShowns[banner.id]) {
            if((new Date()).getTime() < bannerLastShowns[banner.id] + (banner.shown_delay * 1000)) {
                return false;
            }
        }
        const shownCount = bannerShownCounts[banner.id] || 0;
        return !banner.max_shown_count || shownCount < banner.max_shown_count;
    });

    filteredBanners.forEach(banner => {
        if (banner.max_shown_count) {
            bannerShownCounts[banner.id] = (bannerShownCounts[banner.id] || 0) + 1;
        }
        if(banner.shown_delay) {
            bannerLastShowns[banner.id] = (new Date()).getTime();
        }
    });

    await indexedDB.set('mircity-bannerShownCounts', bannerShownCounts);
    await indexedDB.set('mircity-bannerLastShowns', bannerLastShowns);

    return filteredBanners;
}

