export default {
    namespaced: true,

    state: {
        defaultValue: {
            MAIN_PAGE_ASSISTANT_LINK: '/entity/assist/drugoe-poruchenie/product/6655'
        },
        config: {
            MAIN_PAGE_ASSISTANT_LINK: '/entity/assist/drugoe-poruchenie-novoe',
            MAIN_PAGE_ASSISTANT_TEXT: '',
            ENTITY_DELIVERY_ADDRESS_SHOW: true,

            TOWER_ADDRESS_POPUP: true,
            TOWER_ENTITIES_SORT: true,

            SHOW_SERVICES_IN_TWO_ROWS: true,
        }
    },
    mutations: {
        SET_CONFIG(state, payload) {
            state.config = {
                ...state.config,
                ...payload
            };
        },
    },
}