<template>
    <div
        v-if="shown"
        :class="`${ordersStatuses && ordersStatuses.length && ordersStatuses.length !== 1 ? 'status-dialogs-toolbar-all ' : 'status-dialogs-toolbar-single'}`">
        <div
            v-if="ordersStatuses && ordersStatuses.length"
            class="status-dialogs-orders">
            <template v-if="ordersStatuses && ordersStatuses.length == 1 && checkStatus(ordersStatuses[0])">
                <StatusDialogOrderCard :order="ordersStatuses[0]" />
            </template>
            <template v-else>
                <f7-swiper class="status-dialogs-swiper" :params="{ spaceBetween: 0, slidesPerView: 1.1 }">
                    <template v-for="(order, index) in ordersStatuses">
                        <f7-swiper-slide
                            v-if="order && order.id && checkStatus(order)"
                            :key="index"
                        >
                            <StatusDialogOrderCard :order="order" />
                        </f7-swiper-slide>
                    </template>
                </f7-swiper>
            </template>
        </div>
    </div>
</template>


<script>
    import moment from '../../plugins/moment'
    import { mapState } from 'vuex';
    import store from '@/store';
    import { STATUS_DIALOGS_TYPES, DELIVERY_TYPE, ORDER_STATUS, ORDER_PAYMENT_STATUS } from '@/js/utils/vars'
    import { repeatOrder } from '@/js/utils/orders';
    import StatusDialogOrderCard from './StatusDialogOrderCard';

    import { sendAppMetricaEvent, checkEnabledService } from '@/js/utils/service-helpers';

    export default {
        name: 'StatusDialog',

        components: {
            StatusDialogOrderCard
        },

        props: {
            canOpen: {
                type: Boolean,
                default: true,
            },
            statuses: {
                type: Array,
                default: () => ([]),
            },
        },
        
        data: () => ({
            ORDER_STATUS,
            shown: false,
        }),

        computed: {
            ...mapState('orders', ['ordersStatuses'])
        },

        async mounted() {
            await store.dispatch('orders/getOrdersForStatuses', { page: 1 });
            await this.checkOrderStatuses();
            try {
                if(this.$root.getOrdersForStatusesTimerId) {
                    clearInterval(this.$root.getOrdersForStatusesTimerId);
                }
                this.$root.getOrdersForStatusesTimerId = setInterval(async () => {
                    await store.dispatch('orders/getOrdersForStatuses', { page: 1 });
                    this.checkOrderStatuses();
                }, 4000)
            } catch (e) {}
        },

        methods: {
            // async checkRouteName() {
            //     await this.timeout(200);
            //     const routeName = this?.$f7?.views?.main?.router?.url;
            //     if(this.routeName && this.routeName !== '/' && this.routeName !== routeName) {
            //         this.$root.$emit('onboardingClose');
            //     }
            //     if(this.routeName !== routeName && routeName !== '/' && routeName !== '/home') {
            //         this.$root.$emit('onboardingShow', { routeName });
            //         this.routeName = routeName;
            //     }
                
            //     await this.checkRouteName();
            //     return;
            // },
            // startTimer() {
            //     if (this.intervalId) {
            //         clearInterval(this.intervalId);
            //     }
            //     this.intervalId = setInterval(() => {
            //         for (const order of this.ordersStatuses) {
            //             if (order.status === ORDER_STATUS.MEETING_POINT) {
            //                 const currentTime = moment().unix();
            //                 const updatedTime = moment(order.meeting_point_at).unix();
            //                 order.paidWaitingFree = currentTime - updatedTime < this.order.waiting_free_seconds;
            //                 if (order.paidWaitingFree) {
            //                     order.paidWaitingTimer = moment(this.order.waiting_free_seconds - ((currentTime - updatedTime) * 1000)).format('HH:mm:ss');
            //                 } else {
            //                     order.paidWaitingTimer = moment((currentTime - updatedTime) * 1000).format('HH:mm:ss');
            //                 }
            //             }
            //         }
            //     }, 1000);
            // },
            async checkOrderStatuses() {
                if (this.ordersStatuses && this.ordersStatuses.length > 0) {
                    for (const order of this.ordersStatuses) {
                        if (order?.status === 5 && !!order?.delivered_door === true) {
                            order.status = 25;
                        }
                    }
                    this.ordersStatuses.sort(order => order.id);
                    this.$root.$emit('statusDialogShown');
                    this.shown = true;
                    sendAppMetricaEvent('STATUS_DIALOGS', {
                        Action: 'open',
                    });
                } else {
                    this.shown = false;
                    this.$root.$emit('statusDialogHide');
                }
            },
            getStatus(order) {
                if ((order.status === ORDER_STATUS.SUBMITTED || order.status === ORDER_STATUS.PREPARED) && this.processingPayment(order)) {
                    return 'Платеж обрабатывается';
                }
                if (!order.statusDialogs.length) {
                    return;
                }
                for (const statusDialog of order.statusDialogs) {
                    if (order.status === statusDialog.status) {
                        if ((order?.gd?.items[0]?.grocer?.status_postpay && statusDialog.postpaid) || (!order.gd && statusDialog.postpaid)) {
                            if (order.delivery_type && (order.delivery_type === DELIVERY_TYPE.DOOR || order.delivery_type === DELIVERY_TYPE.COLLECT)) { // постоплата до двери
                                return this.labelStatusType(statusDialog.postpaid_door, order.code);
                            }
                            if (order.delivery_type && order.delivery_type === DELIVERY_TYPE.MEETING_POINT) { // постоплата в точку встречи
                                return this.labelStatusType(statusDialog.postpaid_meeting_point, order.code);
                            }
                            if (order.delivery_type && order.delivery_type === DELIVERY_TYPE.WITHOUT) { // постоплата без доставки
                                return this.labelStatusType(statusDialog.postpaid_without, order.code);
                            }
                        } else {
                            if (order.delivery_type && (order.delivery_type === DELIVERY_TYPE.DOOR || order.delivery_type === DELIVERY_TYPE.COLLECT)) { // до двери
                                return this.labelStatusType(statusDialog.prepayment_door, order.code);
                            }
                            if (order.delivery_type && order.delivery_type === DELIVERY_TYPE.MEETING_POINT) { // в точку встречи
                                return this.labelStatusType(statusDialog.prepayment_meeting_point, order.code);
                            }
                            if (order.delivery_type && order.delivery_type === DELIVERY_TYPE.WITHOUT) { // без доставки
                                return this.labelStatusType(statusDialog.prepayment_without, order.code);
                            }
                        }
                    
                    }
                }
                return;
            },
            processingPayment(order) {
                return order && order.payments ? order.payments.find(item => item.status === ORDER_PAYMENT_STATUS.PROCESSING) : false
            },
            payOrder(order) {
                sendAppMetricaEvent('STATUS_DIALOGS_PAY_ORDER', {
                    Action: 'pay',
                });
                this.$f7router.navigate({
                    name: 'payment',
                    params: {
                        orderType: order.type,
                        orderId: order.id
                    },
                });
            },
            getImgStatus(order) {
                if (!order.statusDialogs.length) {
                    return;
                }
                for (const statusDialog of order.statusDialogs) {
                    if (order.status === statusDialog.status) {
                        if ((order?.gd?.items[0]?.grocer?.status_postpay && statusDialog.postpaid) || (!order.gd && statusDialog.postpaid)) {
                            if (order.delivery_type && (order.delivery_type === DELIVERY_TYPE.DOOR || order.delivery_type === DELIVERY_TYPE.COLLECT)) { // постоплата до двери
                                return this.imgStatusType(statusDialog.postpaid_door);
                            }
                            if (order.delivery_type && order.delivery_type === DELIVERY_TYPE.MEETING_POINT) { // постоплата в точку встречи
                                return this.imgStatusType(statusDialog.postpaid_meeting_point);
                            }
                            if (order.delivery_type && order.delivery_type === DELIVERY_TYPE.WITHOUT) { // постоплата без доставки
                                return this.imgStatusType(statusDialog.postpaid_without);
                            }
                        } else {
                            if (order.delivery_type && (order.delivery_type === DELIVERY_TYPE.DOOR || order.delivery_type === DELIVERY_TYPE.COLLECT)) { // до двери
                                return this.imgStatusType(statusDialog.prepayment_door);
                            }
                            if (order.delivery_type && order.delivery_type === DELIVERY_TYPE.MEETING_POINT) { // в точку встречи
                                return this.imgStatusType(statusDialog.prepayment_meeting_point);
                            }
                            if (order.delivery_type && order.delivery_type === DELIVERY_TYPE.WITHOUT) { // без доставки
                                return this.imgStatusType(statusDialog.prepayment_without);
                            }
                        }
                    }
                }
                return
            },
            imgStatusType(statusType) {
                switch (statusType) {
                case STATUS_DIALOGS_TYPES.NOT_PAID: // не оплачен
                    return 'NotPay';
                case STATUS_DIALOGS_TYPES.AWAITING: // собираем
                    return 'Assembly';
                case STATUS_DIALOGS_TYPES.COOKING: // готовим
                    return 'Cooking';
                case STATUS_DIALOGS_TYPES.DELIVERY: // консьерж в пути
                    return 'DeliveryLeg';
                case STATUS_DIALOGS_TYPES.DONE: // доставлен
                    return 'Checkround';
                case STATUS_DIALOGS_TYPES.CANCELED: // отменен 
                    return 'Cancel2';
                case STATUS_DIALOGS_TYPES.AWAITING_READY: // готов к выдаче (в рест , в маг..)
                    return 'Shop';
                case STATUS_DIALOGS_TYPES.MEETING_POINT: // в точке встречи
                    return 'MeetingPoint';
                case STATUS_DIALOGS_TYPES.NOT_PAID_POST: // заказ принят (постоплата)
                    return 'OrderAccepted';
                case STATUS_DIALOGS_TYPES.IN_WORK: // в работе
                    return 'Assembly';
                case STATUS_DIALOGS_TYPES.COMPLETED: // заказ выполнен (постоплата)
                    return 'Checkround';
                case STATUS_DIALOGS_TYPES.DELIVERED_DOOR: // доставлен до двери
                    return 'Checkround';
                default:
                    return;
                }
            },
            labelStatusType(statusType, serviceName) {
                const types = [];
                if (serviceName === 'restaurant') {
                    types.push({ value: STATUS_DIALOGS_TYPES.NOT_PAID, label: 'Не оплачен' });
                    types.push({ value: STATUS_DIALOGS_TYPES.COOKING, label: 'Готовим' });
                    types.push({ value: STATUS_DIALOGS_TYPES.AWAITING_READY, label: 'Готов к выдаче в ресторане' });
                    types.push({ value: STATUS_DIALOGS_TYPES.CANCELED, label: 'Отменен' });
                    types.push({ value: STATUS_DIALOGS_TYPES.DELIVERY, label: 'Консьерж в пути' });
                    types.push({ value: STATUS_DIALOGS_TYPES.MEETING_POINT, label: 'Готов к выдаче в точке встречи' });
                    types.push({ value: STATUS_DIALOGS_TYPES.DONE, label: 'Доставлен' });
                    types.push({ value: STATUS_DIALOGS_TYPES.COMPLETED, label: 'Уже у вас' });
                    types.push({ value: STATUS_DIALOGS_TYPES.DELIVERED_DOOR, label: 'Доставлен до двери' });
                } else if (serviceName === 'grocery') {
                    types.push({ value: STATUS_DIALOGS_TYPES.NOT_PAID, label: 'Не оплачен' });
                    types.push({ value: STATUS_DIALOGS_TYPES.NOT_PAID_POST, label: 'Заказ принят' });
                    types.push({ value: STATUS_DIALOGS_TYPES.AWAITING, label: 'Собираем' });
                    types.push({ value: STATUS_DIALOGS_TYPES.DELIVERY, label: 'Консьерж в пути' });
                    types.push({ value: STATUS_DIALOGS_TYPES.DONE, label: 'Доставлен' });
                    types.push({ value: STATUS_DIALOGS_TYPES.CANCELED, label: 'Отменен' });
                    types.push({ value: STATUS_DIALOGS_TYPES.AWAITING_READY, label: 'Готов к выдаче в магазине' });
                    types.push({ value: STATUS_DIALOGS_TYPES.MEETING_POINT, label: 'Готов к выдаче в точке встречи' });
                    types.push({ value: STATUS_DIALOGS_TYPES.COMPLETED, label: 'Уже у вас' });
                    types.push({ value: STATUS_DIALOGS_TYPES.DELIVERED_DOOR, label: 'Доставлен до двери' });
                } else {
                    types.push({ value: STATUS_DIALOGS_TYPES.NOT_PAID_POST, label: 'Заказ принят' });
                    types.push({ value: STATUS_DIALOGS_TYPES.NOT_PAID, label: 'Не оплачен' });
                    types.push({ value: STATUS_DIALOGS_TYPES.AWAITING, label: 'Собираем' });
                    types.push({ value: STATUS_DIALOGS_TYPES.COOKING, label: 'Готовим' });
                    types.push({ value: STATUS_DIALOGS_TYPES.IN_WORK, label: 'В работе' });
                    types.push({ value: STATUS_DIALOGS_TYPES.DELIVERY, label: 'Консьерж в пути' });
                    types.push({ value: STATUS_DIALOGS_TYPES.DONE, label: 'Доставлен' });
                    types.push({ value: STATUS_DIALOGS_TYPES.CANCELED, label: 'Отменен' });
                    types.push({ value: STATUS_DIALOGS_TYPES.AWAITING_READY, label: 'Готов к выдаче' });
                    types.push({ value: STATUS_DIALOGS_TYPES.MEETING_POINT, label: 'В точке встречи' });
                    types.push({ value: STATUS_DIALOGS_TYPES.COMPLETED, label: 'Уже у вас' });
                    types.push({ value: STATUS_DIALOGS_TYPES.DELIVERED_DOOR, label: 'Доставлен до двери' });
                }
                for (const type of types) {
                    if (type.value === statusType) {
                        return type.label;
                    }
                }
                return '';
            },
            async repeatOrder(order) {
                sendAppMetricaEvent('STATUS_DIALOGS_REPEAT_ORDER', {
                    Action: 'repeat',
                });
                await repeatOrder(order);
            },
            checkStatus(order) {
                if (!order) {
                    return;
                }
                for (const statusDialog of order.statusDialogs) {
                    if (order.status === statusDialog.status) {
                        if ((order?.gd?.items[0]?.grocer?.status_postpay && statusDialog.postpaid) || (!order.gd && statusDialog.postpaid)) {
                            if (order.delivery_type && (order.delivery_type === DELIVERY_TYPE.DOOR || order.delivery_type === DELIVERY_TYPE.COLLECT)) {
                                return statusDialog.postpaid_door && statusDialog.postpaid_door !== 0;
                            }
                            if (order.delivery_type && order.delivery_type === DELIVERY_TYPE.MEETING_POINT) {
                                return statusDialog.postpaid_meeting_point && statusDialog.postpaid_meeting_point !== 0;
                            }
                            if (order.delivery_type && order.delivery_type === DELIVERY_TYPE.WITHOUT) {
                                return statusDialog.postpaid_without && statusDialog.postpaid_without !== 0;
                            }
                        } else {
                            if (order.delivery_type && (order.delivery_type === DELIVERY_TYPE.DOOR || order.delivery_type === DELIVERY_TYPE.COLLECT)) {
                                return statusDialog.prepayment_door && statusDialog.prepayment_door !== 0;
                            }
                            if (order.delivery_type && order.delivery_type === DELIVERY_TYPE.MEETING_POINT) {
                                return statusDialog.prepayment_meeting_point && statusDialog.prepayment_meeting_point !== 0;
                            }
                            if (order.delivery_type && order.delivery_type === DELIVERY_TYPE.WITHOUT) {
                                return statusDialog.prepayment_without && statusDialog.prepayment_without !== 0;
                            }
                        }
                    }
                }
                return false;
            },
            checkPostpaid(order) {
                if (!order) {
                    return;
                }
                for (const statusDialog of order.statusDialogs) {
                    if (order.status === statusDialog.status) {
                        if (order.gd) {
                            return !order?.gd?.items[0]?.grocer?.status_postpay;
                        } else {
                            return !statusDialog.postpaid;
                        }
                    }
                }
            },
            checkEnabledOrder(order) {
                return checkEnabledService(order);
            },
            openOrder(order) {
                sendAppMetricaEvent('STATUS_DIALOGS_OPEN_ORDER', {
                    Action: 'open',
                });
                const orderId = order.id;
                if (!order?.gd?.items[0]?.grocer?.status_postpay && order.status === ORDER_STATUS.SUBMITTED || order.status === ORDER_STATUS.CANCELED) {
                    return;
                }
                this.$f7router.navigate({
                    name: 'orders-detail',
                    params: {
                        orderId
                    }
                });
            },
        }
    };
</script>

<style lang="scss">
.status-dialogs-toolbar-single {
    position: fixed;
    width: 100vw;
    max-width: 600px;
    left: 0;
    padding: 0 30px;
    bottom: calc(constant(safe-area-inset-bottom) + 80px);
    bottom: calc(env(safe-area-inset-bottom) + 80px);
    z-index: 10000;
}
.status-dialogs-toolbar-all {
    position: fixed;
    width: 100vw;
    max-width: 600px;
    padding: 0;
    // margin: 0 -20px;
    bottom: calc(constant(safe-area-inset-top) + 80px);
    bottom: calc(env(safe-area-inset-top) + 80px);
    z-index: 10000;
}
.status-dialogs-orders {
    // z-index: 10000;
    // position: fixed;
    // bottom: 25%;
    .swiper-slide {
        padding-left: 20px;
        &:last-child {
            padding-right: 20px;
        } 
    }
}
.status-dialogs-swiper {
    .swiper-slide {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
}
</style>
