import moment from '../../plugins/moment';
import { f7 } from 'framework7-vue';
import { Device } from 'framework7';
import store from '@/store';
import axios from '../../plugins/axios';
import indexedDB from '../../plugins/indexedDB';
import { ORDER_TYPE, PUSH_TYPE } from '@/js/utils/vars';
import { createModal, addItemToCart } from '@/js/utils/service-helpers';
import { BUSINESS_TYPE, DELIVERY_TYPE } from './vars';
const { version } = require('../../../package.json');


export const repeatOrder = async order => {
    const prom = createModal({
        modalText: 'В корзине уже есть товары. Если вы продолжите, то они будут удалены из корзины. Повторить заказ?',
        okText: 'Давайте',
        rejectText: 'Нет',
        open: store.state.cart.items.length > 0
    });

    prom.then(() => {
        _repeatOrder(order);
    });
}

const _repeatOrder = async order => {
    store.commit('cart/CLEAR');
    if(order.type === ORDER_TYPE.RESTAURANT_DELIVERY) {
        let cartItem = {};
        const entity_id = order.rd.items[0].restaurant_id;
        const ids = order.rd.items.map(item => item.dish_id);
        const { data: entity } = await axios.get(`/front/restaurants/${entity_id}`);
        const { data: products } = await axios.get(`/front/restaurants/${entity_id}/dishes/items?ids=${JSON.stringify(ids)}&orderId=${order?.id}`);
        for(const item of order.rd.items.filter(i=> i.enabled)) {
            const modifierIds = (item.modifiers || []).map(v => v.id);
            const ingredientIds = (item.ingredients || []).map(v => v.id);
            const description = [].concat(
                (item.modifiers || []).map(v => v.title),
                (item.ingredients || []).map(v => {
                    if (item?.restaurant?.multiple_ingridients) {
                        return `${v.quantity > 0 ? v.quantity + 'x ' : ''}${v.title}`;
                    } else {
                        return v.title;
                    }
                })
            ).join(' • ');
            let deleted = true;

            for(const product of products) {
                if(item.dish_id === product.id) {
                    deleted = false;
                    let modifiers = [];
                    let ingredients = [];
                    let item_price = +product.price;
                    let old_item_price = +product.old_price;
                    for(const modifier of product.modifiers) {
                        if(modifierIds.indexOf(modifier.id) >= 0) {
                            item_price += +modifier.price;
                            old_item_price += +modifier.old_price;
                            if(!modifiers.find(m => m.id === modifier.id)) {
                                modifiers.push(modifier);
                            }
                        }
                    }
                    if (product?.modifierGroups?.length) {
                        for (const group of product.modifierGroups) {
                            for(const modifier of group.modifiers) {
                                if(modifierIds.indexOf(modifier.id) >= 0) {
                                    item_price += +modifier.price;
                                    old_item_price += +modifier.old_price;
                                    if(!modifiers.find(m => m.id === modifier.id)) {
                                        modifiers.push(modifier);
                                    }
                                }
                            }
                        }
                    }
                    for(const ingredient of product.ingredients) {
                        if(ingredientIds.indexOf(ingredient.id) >= 0) {
                            item_price += +ingredient.price;
                            old_item_price += +ingredient.old_price;
                            if(!ingredients.find(i => i.id === ingredient.id)) {
                                ingredients.push(ingredient);
                            }
                        }
                    }
                    if (product?.ingredientGroups?.length) {
                        for (const group of product.ingredientGroups) {
                            for(const ingredient of group.ingredients) {
                                if(ingredientIds.indexOf(ingredient.id) >= 0) {
                                    item_price += +ingredient.price;
                                    old_item_price += +ingredient.old_price;
                                    if(!ingredients.find(i => i.id === ingredient.id)) {
                                        ingredients.push(ingredient);
                                    }
                                }
                            }
                        }
                    }

                    if(modifiers.length === item.modifiers.length && ingredients.length === item.ingredients.length) {
                        cartItem = {
                            entity_type_code: 'restaurant',
                            entity_code: entity.code,
                            categories_page: !!entity.categories_page,
                            restaurant_id: entity_id,
                            entity_title: entity.title,
                            dish_id: item.dish_id,
                            id: item.dish_id,
                            title: item.dish_title,
                            description,
                            picture: product.picture,
                            price: product.price,
                            old_price: product.old_price,
                            item_price: item_price,
                            old_item_price: old_item_price ? old_item_price : null,
                            modifiers,
                            ingredients,
                            quantity: item.quantity,
                            weight: item.weight || 0,
                            weight_step: product.weight_step || 0,
                            weight_unit: product.weight_unit || 0,
                            weight_limit: item.weight_limit || 0,
                            weight_limit_description: item.weight_limit_description || '',
                            deleted: false,
                            calories: item.calories,
                            carbohydrates: item.carbohydrates,
                            fat: item?.fat,
                            protein: item?.protein
                        };
                    } else {
                        cartItem = {
                            entity_type_code: 'restaurant',
                            entity_code: entity.code,
                            categories_page: !!entity.categories_page,
                            restaurant_id: entity_id,
                            entity_title: entity.title,
                            dish_id: item.dish_id,
                            id: item.dish_id,
                            title: item.dish_title,
                            modifiers: item.modifiers || [],
                            ingredients: item.ingredients || [],
                            quantity: item.quantity,
                            weight: item.weight || 0,
                            weight_step: product.weight_step || 0,
                            weight_unit: product.weight_unit || 0,
                            weight_limit: item.weight_limit || 0,
                            weight_limit_description: item.weight_limit_description || '',
                            description,
                            picture: product.picture,
                            deleted: true,
                            calories: item.calories,
                            carbohydrates: item.carbohydrates,
                            fat: item?.fat,
                            protein: item?.protein
                        };
                    }
                }
            }
            if(deleted) {
                cartItem = {
                    entity_type_code: 'restaurant',
                    entity_code: entity.code,
                    categories_page: !!entity.categories_page,
                    restaurant_id: entity_id,
                    entity_title: entity.title,
                    dish_id: item.dish_id,
                    id: item.dish_id,
                    title: item.dish_title,
                    modifiers: item.modifiers || [],
                    ingredients: item.ingredients || [],
                    weight: item.weight,
                    weight_step: item.weight_step || 0,
                    weight_unit: item.weight_unit || 0,
                    weight_limit: entity.weight_limit,
                    weight_limit_description: entity.weight_limit_description,
                    description,
                    deleted: true,
                    calories: item.calories,
                    carbohydrates: item.carbohydrates,
                    fat: item?.fat,
                    protein: item?.protein
                };
            }
            addItemToCart({ 
                orderType: ORDER_TYPE.RESTAURANT_DELIVERY, 
                paymentType: order.payment_type, 
                cartItem
            }, () => {});
        }
    }
    if(order.type === ORDER_TYPE.GROCER_DELIVERY) {
        let cartItem = {};
        const entity_id = order.gd.items[0].grocer_id;
        const ids = order.gd.items.map(item => item.goods_id);
        const { data: entity } = await axios.get(`/front/grocers/${entity_id}`);
        const { data: products } = await axios.get(`/front/grocers/${entity_id}/product/items?ids=${JSON.stringify(ids)}`);
        for(const item of order.gd.items.filter(i=> i.enabled)) {
            let deleted = true;
            for(const product of products) {
                if(item.goods_id === product.id) {
                    deleted = false;
                    cartItem = {
                        entity_type_code: 'grocer',
                        entity_code: entity.code,
                        categories_page: !!entity.categories_page,
                        entity_title: entity.title,
                        grocer_id: entity_id,
                        goods_id: item.goods_id,
                        id: item.goods_id,
                        picture: product.main_picture,
                        title: item.goods_title,
                        price: product.price,
                        old_price: product.old_price,
                        item_price: product.price,
                        old_item_price: product.old_price,
                        quantity: item.quantity,
                        weight: product.weight,
                        weight_step: product.weight_step || 0,
                        weight_unit: product.weight_unit || 0,
                        weight_limit: entity.weight_limit,
                        weight_limit_description: entity.weight_limit_description,
                    };
                }
            }
            if(deleted) {
                cartItem = {
                    entity_type_code: 'grocer',
                    entity_code: entity.code,
                    categories_page: !!entity.categories_page,
                    grocer_id: entity_id,
                    entity_title: entity.title,
                    dish_id: item.goods_id,
                    id: item.goods_id,
                    title: item.goods_title,
                    weight: item.weight,
                    weight_step: item.weight_step || 0,
                    weight_unit: item.weight_unit || 0,
                    weight_limit: entity.weight_limit,
                    weight_limit_description: entity.weight_limit_description,
                    deleted: true
                };
            }
            addItemToCart({ 
                orderType: ORDER_TYPE.GROCER_DELIVERY, 
                paymentType: order.payment_type, 
                cartItem
            }, () => {});
        }
    }
    if(order.type === ORDER_TYPE.ENTITY_DELIVERY) {
        let cartItem = {};
        const entity_id = order.ed.items[0].entity_id;
        const ids = order.ed.items.map(item => item.entity_product_id);
        const { data: entity } = await axios.get(`/front/entities/get/${entity_id}`);
        const { data: products } = await axios.get(`/front/entities/${entity_id}/entity_products/items?ids=${JSON.stringify(ids)}`);
        for(const item of order.ed.items.filter(i=> i.enabled)) {
            const entityVariantIds = (item.entity_variants || []).map(v => v.id);
            const entityOptionIds = (item.entity_options || []).map(v => v.id);
            const description = [].concat(
                (item.entity_variants || []).map(v => v.title),
                (item.entity_options || []).map(v => v.title)
            ).join(' • ');
            let deleted = true;

            for(const product of products) {
                if(item.entity_product_id === product.id) {
                    deleted = false;
                    let entityVariants = [];
                    let entityOptions = [];
                    let item_price = +product.price;
                    let old_item_price = +product.old_price;
                    for(const entityVariant of product.entityVariants) {
                        if(entityVariantIds.indexOf(entityVariant.id) >= 0) {
                            item_price += +entityVariant.price;
                            old_item_price += +entityVariant.old_price;
                            if(!entityVariants.find(m => m.id === entityVariant.id)) {
                                entityVariants.push(entityVariant);
                            }
                        }
                    }
                    for(const entityOption of product.entityOptions) {
                        if(entityOptionIds.indexOf(entityOption.id) >= 0) {
                            item_price += +entityOption.price;
                            old_item_price += +entityOption.old_price;
                            if(!entityOptions.find(i => i.id === entityOption.id)) {
                                entityOptions.push(entityOption);
                            }
                        }
                    }

                    if(entityVariants.length === item.entity_variants.length && entityOptions.length === item.entity_options.length) {
                        cartItem = {
                            entity_type_code: entity?.entityType?.code,
                            entity_code: entity?.code,
                            categories_page: !!entity?.categories_page,
                            entity_title: entity?.title,
                            entity_id: item.entity_id,
                            entity_product_id: item.entity_product_id,
                            id: item.entity_product_id,
                            title: item.entity_product_title,
                            description,
                            picture: product.picture,
                            price: product.price,
                            old_price: product.old_price,
                            item_price: item_price,
                            old_item_price: old_item_price ? old_item_price : null,
                            entityVariants,
                            entityOptions,
                            quantity: item.quantity,
                            weight: product.weight,
                            weight_step: product.weight_step || 0,
                            weight_unit: product.weight_unit || 0,
                            weight_limit: entity.weight_limit,
                            weight_limit_description: entity.weight_limit_description,
                            deleted: false
                        };
                    } else {
                        cartItem = {
                            entity_type_code: entity?.entityType?.code,
                            entity_code: entity?.code,
                            categories_page: !!entity?.categories_page,
                            entity_title: entity?.title,
                            entity_id: item.entity_id,
                            entity_product_id: item.entity_product_id,
                            entityVariants: item.entity_variants || [],
                            entityOptions: item.entity_options || [],
                            id: item.entity_product_id,
                            title: item.entity_product_title,
                            description,
                            picture: product.picture,
                            quantity: item.quantity,
                            weight: product.weight,
                            weight_step: product.weight_step || 0,
                            weight_unit: product.weight_unit || 0,
                            weight_limit: entity.weight_limit,
                            weight_limit_description: entity.weight_limit_description,
                            deleted: true
                        };
                    }
                }
            }
            if(deleted) {
                cartItem = {
                    entity_type_code: entity?.entityType?.code,
                    entity_code: entity?.code,
                    categories_page: !!entity?.categories_page,
                    entity_title: entity.title,
                    entity_id: item.entity_id,
                    entity_product_id: item.entity_product_id,
                    id: item.entity_product_id,
                    title: item.entity_product_title,
                    entityVariants: item.entity_variants || [],
                    entityOptions: item.entity_options || [],
                    description,
                    weight_step: item.weight_step || 0,
                    weight_unit: item.weight_unit || 0,
                    weight_limit: entity.weight_limit,
                    weight_limit_description: entity.weight_limit_description,
                    deleted: true
                };
            }
            addItemToCart({ 
                orderType: ORDER_TYPE.ENTITY_DELIVERY, 
                paymentType: order.payment_type, 
                cartItem
            }, () => {});
        }
    }
    f7.views[0].router.navigate({
        name: 'cart'
    });
}