<template>
    <f7-sheet
        :class="`error-message-sheet ${sheet.type ? 'error-message-sheet__' + sheet.type : ''}`"
        top
        :backdrop="false"
        swipe-to-close>
        <div class="error-message-sheet__content">
            <div v-if="sheet.type === 'timeout'" class="error-message-sheet__img"></div>
            <div>
                <div class="error-message-sheet__title">{{ sheet.title }}</div>
                <div class="error-message-sheet__text">{{ sheet.text }}</div>
            </div>
        </div>
        <div v-if="sheet.type === 'network'" class="error-message-sheet__refresh" @click.stop="refresh"></div>
        <div v-if="sheet.type === 'timeout'" class="error-message-sheet__close" @click.stop="close"></div>
    </f7-sheet>
</template>

<script>
    import { bus } from '../../js/utils/bus';

    export default {
        name: 'ErrorMessageSheet',
        data: () => ({
            shown: false,
            sheet: {
                type: '', // network, timeout, server
                title: 'Нет соединения с сервером',
                text: 'Исправляем ошибку'
            }
        }),
        computed: {
            isErrorPage() {
                return this.$f7.views[0].router.currentRoute.name === 'error';
            }
        },
        mounted() {
            bus.$on('errorMessageSheetShow', async sheet => {
                if(this.isErrorPage) {
                    this.$f7.sheet.close();
                    return;
                }
                this.sheet = sheet;
                this.$f7.sheet.open('.error-message-sheet');
                if(this.sheet !== 'network') {
                    await new Promise(resolve => setTimeout(resolve, 5000));
                    this.$f7.sheet.close();
                }
            });
        },
        methods: {
            refresh() {
                window.location.reload();
            },
            close() {
                this.$f7.sheet.close();
            },
        },
    };
</script>

<style lang="scss">
.error-message-sheet {
    margin: 0 14px;
    width: calc(100vw - 28px);
    height: auto !important;
    background: none;
    box-shadow: none;

    .sheet-modal-inner {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: calc(11px + 70px);
        padding: 10px 23px 10px 15px !important;
        background: #F54842;
        border-radius: 8px;
    }

    &__timeout {
        .sheet-modal-inner {
            background: #1d3c5fe6;
        }
    }

    &__server {
        .sheet-modal-inner {
            background: #72808de6;
        }
    }

    &__content {
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }

    &__img {
        width: 28px;
        height: 20px;
        margin-right: 15px;
        background: url('../../static/rossed-out-cloud.svg') center center no-repeat;
        background-size: contain;
    }
    &__title {
        font-size: 16px;
        font-weight: 800;
        line-height: 20px;
        letter-spacing: -0.02em;
        color: #FFFFFF;
    }
    &__text {
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: -0.02em;
        color: #FFFFFF;
    }
    &__close {
        width: 16px;
        height: 16px;
        background: url('../../static/close-button-black.svg') center center no-repeat;
        background-size: contain;
    }
    &__refresh {
        width: 26px;
        height: 26px;
        background: url('../../static/refresh-arrow-white.svg') center center no-repeat;
        background-size: contain;
    }
}
</style>
