import axios from 'axios';
import store from '../store';
import indexedDB from './indexedDB';
import { f7 } from 'framework7-vue';
import { bus } from '../js/utils/bus';
import { axiosConfig } from '../js/utils/axios-config';

const PAGE_ERRORS_COUNT = 5;
let errorRquestsCount = 0;

const axiosInstance = axios.create({
    baseURL: process.env.VUE_APP_API_URL,
    timeout: 60000
})

axiosInstance.interceptors.request.use(async config => {
    return await axiosConfig(config);
}, function (error) {
    return Promise.reject(error);
});

axiosInstance.interceptors.response.use(function (response) {
    errorRquestsCount = 0;
    return response;
}, async function (error) {
    errorRquestsCount += 1;

    if(errorRquestsCount > PAGE_ERRORS_COUNT) {
        f7.views[0].router.navigate(`/error`);
        return Promise.reject(error);
    }
    if(error.code === 'ECONNABORTED') {
        bus.$emit('errorMessageSheetShow', {
            type: 'timeout',
            title: 'Нет соединения с сервером',
            text: 'Исправляем ошибку'
        });
        // bus.$emit('ErrorMessageSheetFullscreenShow', { error: 'timeout', request: error.config.method + ' ' + error.config.url });
    }
    if(error.toJSON() && error.toJSON().message === "Network Error") {
        bus.$emit('errorMessageSheetShow', {
            type: 'network',
            title: 'Нет соединения с Интернетом',
            text: 'Проверьте подключение'
        });
        // bus.$emit('ErrorMessageSheetFullscreenShow', { error: 'timeout', request: error.config.method + ' ' + error.config.url });
    }
    if(error.response) {
        const { status } = error.response;
        if(status === 401) {
            store.dispatch('auth/logout');
            store.dispatch('cart/recalculate');
            f7.views[0].router.navigate('/login', {
                props: {
                    from: await indexedDB.get('mircity-loginFromPath')
                }
            });
        } else {
            bus.$emit('errorMessageSheetShow', {
                type: 'server',
                title: '',
                text: 'Исправляем ошибку сервера'
            });
            // bus.$emit('ErrorMessageSheetFullscreenShow', { error: status, request: error.config.method + ' ' + error.config.url });
            // f7.views[0].router.navigate(`/error/${status}`);
        }
    }
    return Promise.reject(error);
});

export default axiosInstance;
