import axios from '../../plugins/axios';
import indexedDB from '../../plugins/indexedDB';
import { CACHE_TIME } from '../../js/utils/vars';

export const namespaced = true;

export const state = () => ({
    promocode_read: true,
    homeLoaded: false,
    newVersion: false,
    settings: {
        home: {
            template: '1-2-2',
            services_list: [
                {
                    'title': 'Рестораны (для главной)',
                    'value': 'banner',
                    'active': true,
                    'height': 67,
                    'heightPercent': 45,
                    'default_picture': '4e07HHMlalwlEPAX29Vwm.png',
                    'bannerId': 27,
                    'horizontal': false,
                    'show_on_pages': {},
                    'title_formatted': 'Рестораны (для главной)',
                    'id': 27,
                    'description': null,
                    'type': 'link',
                    'hide_main_banner': 0,
                    'entity_selection_carousel': 0,
                    'max_order_count': 0,
                    'button_name': null,
                    'for_popup_banner': 0,
                    'for_selection_carousel': 0,
                    'code': 'MPRESTS',
                    'authorized_client': 0,
                    'new_client': 0,
                    'for_main_page': 1,
                    'for_mircity_banner': 0,
                    'extended_schedule': 0,
                    'hide': 0,
                    'selection_picture': null,
                    'desktop_picture': null,
                    'picture_for_product': null,
                    'main_text': 'Рестораны',
                    'additional_text': null,
                    'route_name': 'pharmacy-list',
                    'link': '/restaurant',
                    'stories_id': null,
                    'entity_id_for_product': 0,
                    'products_list': [],
                    'list': [],
                    'position': 31,
                    'place': 0,
                    'enabled': 1,
                    'start_version': null,
                    'finish_version': null,
                    'created_at': '2022-03-04T07:26:19.000Z',
                    'updated_at': '2023-06-13T09:02:30.000Z',
                    'schedule': []
                },
                {
                    'title': 'Поручение',
                    'value': 'banner',
                    'active': true,
                    'height': 67,
                    'heightPercent': 45,
                    'default_picture': 'i4BJxTUTYlBJdEx-P8rD_.png',
                    'bannerId': 30,
                    'horizontal': false,
                    'show_on_pages': {},
                    'title_formatted': 'Поручение',
                    'id': 30,
                    'description': null,
                    'type': 'link',
                    'hide_main_banner': 0,
                    'entity_selection_carousel': 0,
                    'max_order_count': 0,
                    'button_name': null,
                    'for_popup_banner': 0,
                    'for_selection_carousel': 0,
                    'code': 'MPLP',
                    'authorized_client': 0,
                    'new_client': 0,
                    'for_main_page': 1,
                    'for_mircity_banner': 0,
                    'extended_schedule': 0,
                    'hide': 0,
                    'selection_picture': null,
                    'desktop_picture': null,
                    'picture_for_product': null,
                    'main_text': 'Выполним поручение',
                    'additional_text': null,
                    'route_name': 'pharmacy-list',
                    'link': '/entity/assist/drugoe-poruchenie/product/6655',
                    'stories_id': null,
                    'entity_id_for_product': 0,
                    'products_list': [],
                    'list': [],
                    'position': 28,
                    'place': 0,
                    'enabled': 1,
                    'start_version': null,
                    'finish_version': null,
                    'created_at': '2022-03-25T00:17:30.000Z',
                    'updated_at': '2023-06-13T08:24:55.000Z',
                    'schedule': []
                },
                {
                    'title': 'Магазины (для главной)',
                    'value': 'banner',
                    'active': true,
                    'height': 67,
                    'heightPercent': 45,
                    'default_picture': '-RTsqns_dCBaL2xQlZDex.png',
                    'bannerId': 26,
                    'horizontal': false,
                    'show_on_pages': {},
                    'title_formatted': 'Магазины (для главной)',
                    'id': 26,
                    'description': null,
                    'type': 'link',
                    'hide_main_banner': 0,
                    'entity_selection_carousel': 0,
                    'max_order_count': 0,
                    'button_name': null,
                    'for_popup_banner': 0,
                    'for_selection_carousel': 0,
                    'code': 'MPSHOPS',
                    'authorized_client': 0,
                    'new_client': 0,
                    'for_main_page': 1,
                    'for_mircity_banner': 0,
                    'extended_schedule': 0,
                    'hide': 0,
                    'selection_picture': null,
                    'desktop_picture': null,
                    'picture_for_product': null,
                    'main_text': 'Магазины',
                    'additional_text': null,
                    'route_name': 'pharmacy-list',
                    'link': '/grocery',
                    'stories_id': null,
                    'entity_id_for_product': 0,
                    'products_list': [],
                    'list': [],
                    'position': 22,
                    'place': 0,
                    'enabled': 1,
                    'start_version': null,
                    'finish_version': null,
                    'created_at': '2022-03-04T07:20:59.000Z',
                    'updated_at': '2023-06-13T09:01:49.000Z',
                    'schedule': []
                },
                {
                    'title': 'Цветы и подарки',
                    'value': 'entity',
                    'active': true,
                    'height': 67,
                    'heightPercent': 45,
                    'default_picture': 'STYgTDoLOEggMyl-Tl3Lm.png',
                    'typeCode': 'gifts',
                    'horizontal': false,
                    'show_on_pages': {},
                    'title_formatted': 'Цветы и подарки'
                },
                {
                    'title': 'Одежда и <br> другие товары',
                    'value': 'entity',
                    'active': true,
                    'height': 37,
                    'default_picture': 'cloth.png',
                    'typeCode': 'serv',
                    'horizontal': true,
                    'auto_rotation': false,
                    'show_on_pages': {},
                    'switching_time': null,
                    'title_formatted': 'Одежда и другие <br> товары'
                },
                {
                    'title': 'Красота и уход',
                    'value': 'entity',
                    'active': true,
                    'height': 67,
                    'heightPercent': 45,
                    'default_picture': 'rJd8u76Bff4jtd6HMF7l_.png',
                    'typeCode': 'beauty',
                    'horizontal': false,
                    'auto_rotation': false,
                    'show_on_pages': {},
                    'switching_time': null,
                    'title_formatted': 'Красота и уход'
                },
                {
                    'title': 'Химчистки и бытовые услуги',
                    'value': 'entity',
                    'active': true,
                    'height': 67,
                    'heightPercent': 45,
                    'default_picture': 'LI6FKZTjsTDO1vny4ghXK.png',
                    'typeCode': 'dombyta',
                    'horizontal': false,
                    'auto_rotation': false,
                    'show_on_pages': {},
                    'switching_time': null,
                    'title_formatted': 'Химчистки и бытовые услуги'
                },
                {
                    'title': 'Фуршетное меню',
                    'value': 'entity',
                    'active': false,
                    'height': 67,
                    'heightPercent': 45,
                    'default_picture': 'o4UcaQY3ZYBkdtGIrVWVu.png',
                    'typeCode': 'furshet',
                    'show_on_pages': {},
                    'title_formatted': 'Фуршетное меню'
                },
                {
                    'title': 'Аптека',
                    'value': 'banner',
                    'active': false,
                    'height': 29,
                    'heightPercent': 20,
                    'default_picture': 'YnBadLtThUNvjir_3BiqJ.png',
                    'bannerId': 25,
                    'horizontal': false,
                    'show_on_pages': {},
                    'title_formatted': 'Аптека',
                    'id': 25,
                    'description': null,
                    'type': 'link',
                    'hide_main_banner': 0,
                    'entity_selection_carousel': 0,
                    'max_order_count': 0,
                    'button_name': null,
                    'for_popup_banner': 0,
                    'for_selection_carousel': 0,
                    'code': 'MPPHARMACY',
                    'authorized_client': 0,
                    'new_client': 0,
                    'for_main_page': 1,
                    'for_mircity_banner': 0,
                    'extended_schedule': 0,
                    'hide': 0,
                    'selection_picture': null,
                    'desktop_picture': null,
                    'picture_for_product': null,
                    'main_text': 'Аптеки',
                    'additional_text': null,
                    'route_name': 'pharmacy-list',
                    'link': '/entity/assist/dostavkaapteka/product/9090',
                    'stories_id': null,
                    'entity_id_for_product': 0,
                    'products_list': [],
                    'list': [],
                    'position': 27,
                    'place': 0,
                    'enabled': 1,
                    'start_version': null,
                    'finish_version': null,
                    'created_at': '2022-03-04T07:13:18.000Z',
                    'updated_at': '2022-11-16T10:31:17.000Z',
                    'schedule': []
                },
                {
                    'title': 'Медицинские услуги',
                    'value': 'entity',
                    'active': true,
                    'height': 29,
                    'heightPercent': 20,
                    'default_picture': '0ZBbE9wqdYC9oKXyxd5lc.png',
                    'typeCode': 'medcine',
                    'horizontal': false,
                    'auto_rotation': false,
                    'show_on_pages': {},
                    'switching_time': null,
                    'title_formatted': 'Медицинские услуги'
                },
                {
                    'title': 'Спорт',
                    'value': 'entity',
                    'active': true,
                    'height': 29,
                    'heightPercent': 20,
                    'default_picture': 'vRyvqUW6CfvzEWmemLqO9.png',
                    'typeCode': 'sport',
                    'show_on_pages': {
                        'restaurant': false
                    },
                    'title_formatted': 'Спорт'
                },
                {
                    'title': 'Услуги для бизнеса',
                    'value': 'entity',
                    'active': true,
                    'height': 29,
                    'heightPercent': 20,
                    'default_picture': 'LnJ9zB3vgayrCinSZn2U6.png',
                    'typeCode': 'realty',
                    'horizontal': false,
                    'show_on_pages': {
                        'restaurant': false
                    },
                    'title_formatted': 'Услуги для бизнеса'
                },
                {
                    'title': 'Трансфер и охрана',
                    'value': 'entity',
                    'active': true,
                    'height': 29,
                    'heightPercent': 20,
                    'default_picture': '5K1Msws8aaRBZsWtUr2i2.png',
                    'typeCode': 'transfer',
                    'show_on_pages': {
                        'restaurant': false
                    },
                    'title_formatted': 'Трансфер<br>\nи охрана'
                },
            ],
            lastUpdate: 0
        },
        desktop_menu: {
            menu: [],
            lastUpdate: 0
        },
        payment: {
            lastUpdate: 0
        },
        order_repeat: {
            lastUpdate: 0
        },
        agreement: {
            lastUpdate: 0
        },
        personal_information: {
            lastUpdate: 0
        },
        faq: {
            lastUpdate: 0
        },
        referal_program: {},
        referer_promocode: [],
        stories: [],
        stories_settings: {}
    },
});

export const mutations = {
    CLEAR(state) {
        state.settings = {};
    },
    SET_SETTINGS(state, payload) {
        state.settings[payload.page] = {
            ...state.settings[payload.page],
            ...payload.settings
        };
    },
    SET_FAQ(state, payload) {
        state.settings.faq = payload;
    },
    SET_STORIES(state, payload) {
        state.settings.stories = payload;
    },
    SET_REFERAL_PROGRAM(state, payload) {
        state.settings.referal_program = payload;
    },
    SET_REFERER_PROMOCODE(state, payload) {
        state.settings.referer_promocode = payload;
    },
    SET_CHECK_PROMOCODE_READ(state, payload) {
        state.promocode_read = payload;
    },
    SET_STORIES_SETTINGS(state, payload) {
        state.settings.stories_settings = payload;
    },
    SET_NEW_VERSION(state, payload) {
        state.newVersion = payload;
    }
};

export const actions = {
    setSettings({ commit }, data) {
        commit('SET_SETTINGS', data);
    },
    async fetch({ commit, state }, { page }) {
        try {
            if ((new Date()).getTime() - state.settings[page].lastUpdate > CACHE_TIME) {
                const { data } = await axios.get(`/front/page-settings/${ page }`);
                if(data) {
                    commit('SET_SETTINGS', data);
                    state.settings[page].lastUpdate = (new Date()).getTime();
                }
            }
        } catch (e) {}
        state.homeLoaded = true;
    },
    async getFAQ({ commit }) {
        const { data } = await axios.get('/front/settings/faq');
        commit('SET_FAQ', data);
    },
    async getReferalProgramSettings({ commit }) {
        const { data } = await axios.get('front/settings/referal-program');
        commit('SET_REFERAL_PROGRAM', data);
    },
    async getRefererPromocode({ commit }) {
        const { data } = await axios.get('front/settings/referer-promocode');
        commit('SET_REFERER_PROMOCODE', data);
    },
    async checkPromocodeRead ({ commit }) {
        const { data } = await axios.get('front/settings/promocode-read');
        commit('SET_CHECK_PROMOCODE_READ', data );
    },
    async promocodeReading({ commit }) {
        await axios.post('front/settings/promocode-reading');
    },
    async getStories({ commit }) {
        const { data } = await axios.get('front/settings/stories');
        state.request = null;

        const result = [];
        const placesGroups = {};
        const localData = await indexedDB.get('mircity-storiesIndexes') || {};

        for (const story of data.sort((a, b) => (a.position > b.position) ? -1 : 1)) {
            if (+story.position !== 0) {
                if (placesGroups[+story.position]) {
                    placesGroups[+story.position].push(story);
                } else {
                    placesGroups[+story.position] = [ story ];
                }
            }
        }

        const nextLocalData = {};

        for (const groupKey in placesGroups) {
            const group = placesGroups[groupKey];
            const lastShownId = localData[groupKey] || null;
            const lastShowStoryIndex = lastShownId ? group.findIndex(b => b.id === lastShownId) : -1;
            let currentBanner;
            currentBanner = group[+lastShowStoryIndex + 1] || group[0];
            result.push(currentBanner);
            nextLocalData[groupKey] = currentBanner.id;
        }
        await indexedDB.set('mircity-storiesIndexes', nextLocalData);
        commit('SET_STORIES', result );
    },
    async getStoriesSettings({ commit }) {
        const { data } = await axios.get('front/settings/stories-settings');
        commit('SET_STORIES_SETTINGS', data ? data : {} );
    }
};