<template>
    <div>
        <ReviewAppDialog
            :can-open="canOpen"
            @waiting="checkIfSheetOpen"
            @processing="waitProcessing"
        />
        <ReviewDialog
            :can-open="canOpen"
            @waiting="checkIfSheetOpen"
            @processing="waitProcessing"
        />
        <StatusDialog
            v-if="isHomePage"
            :can-open="canOpen"
            @waiting="checkIfSheetOpen"
            @processing="waitProcessing"
        />
        <RepeatDialog
            :can-open="canOpen"
            @waiting="checkIfSheetOpen"
            @processing="waitProcessing"
        />
        <PopupBanner
            :can-open="canOpen"
            @waiting="checkIfSheetOpen"
            @processing="waitProcessing"
        />
        <Onboarding
            :can-open="canOpen"
            @waiting="checkIfSheetOpen"
            @processing="false"
        />
    </div>
</template>

<script>
    import store from '@/store';
    import { mapState } from 'vuex';
    import ReviewDialog from '@/components/dialogs/ReviewDialog';
    import ReviewAppDialog from '@/components/dialogs/ReviewAppDialog';
    import StatusDialog from '@/components/dialogs/StatusDialog';
    import RepeatDialog from '@/components/dialogs/RepeatDialog';
    import PopupBanner from '@/components/dialogs/PopupBanner';
    import Onboarding from '@/components/dialogs/Onboarding';
    import indexedDB from '@/plugins/indexedDB';

    export default {
        name: 'StatusObserver',

        components: {
            ReviewDialog,
            StatusDialog,
            RepeatDialog,
            PopupBanner,
            Onboarding,
            ReviewAppDialog
        },

        props: {
            routerName: {
                type: String,
                default: ''
            }
        },
        
        data: () => ({
            canOpen: false,
            inProcess: false,
            routeName: null,
            isHomePage: false
        }),

        computed: {
            ...mapState('orders', ['ordersStatuses'])
        },

        watch: {
            async inProcess() {
                await this.checkOrdersInWork();
            },
        },

        // у него есть история показа страницы, с главной перехожу в магазины - магазин и когда с конкретного
        //  магазина нажимаю назад он допом подгружает главную чтобы быстро вернуться назад (т/е/ держит главную страницу загруженной)
        // можно ли как-то отключить?

        async mounted() {
            await this.handleRouteChange();
            this.checkOrdersInterval = setInterval(() => {
                this.handleRouteChange();
            }, 200);

            setTimeout(async () => {
                await this.clearOldStorage();
                this.$root.$on('newOrderInWork', this.checkOrdersInWork);
                await this.checkOrdersInWork();   
            }, 250)
        },
        methods: {
            handleRouteChange() {
                const newUrl = this?.$f7?.views?.main?.router?.url;
                this.isHomePage = (newUrl === '/' || newUrl === '/home');
                if (this.routeName && this.routeName !== '/' && this.routeName !== newUrl) {            
                    this.$root.$emit('onboardingClose');
                }

                // console.log(new Date().getMilliseconds(), '0');
                if (this.routeName !== newUrl) {
                    setTimeout(() => {
                        // console.log(new Date().getMilliseconds(), '1');
                        if (newUrl !== '/' && newUrl !== '/home') {
                            // console.log(new Date().getMilliseconds(), '2');
                            this.$root.$emit('onboardingShow', { routeName: newUrl });
                            this.routeName = newUrl;
                        } else {
                            if (this.ordersStatuses?.length === 0) {
                                this.$root.$emit('onboardingShow', {});
                            }
                            this.routeName = newUrl;
                        }
                    }, 300);
                }
            },
            async checkRouteName() {
                const routeName = this?.$f7?.views?.main?.router?.url;

                if(this.routeName && this.routeName !== '/' && this.routeName !== routeName) {
                    this.$root.$emit('onboardingClose');
                }

                if(this.routeName !== routeName) {
                    if(routeName !== '/' && routeName !== '/home') {
                        this.$root.$emit('onboardingShow', { routeName });
                        this.routeName = routeName;
                    } else {
                        if (this.ordersStatuses?.length === 0) {
                            this.$root.$emit('onboardingShow', {});
                            this.routeName = routeName;
                        }
                    }
                }
                
                return;
            },

            async checkIfSheetOpen() {
                await this.timeout(2000);
                const sheet = this?.$f7?.sheet?.get();
                if (sheet?.opened) {
                    this.canOpen = false;
                    this.checkIfSheetOpen();
                    return;
                }

                this.canOpen = true;
                return;
            },

            async checkOrdersInWork() {
                if (this.inProcess) {
                    return;
                }
                await this.timeout(10000);

                let ordersInWork = await indexedDB.get('mircity-ordersInWork') || [];
                if (!ordersInWork?.length) {
                    return;
                }

                try {
                    const ordersFromServer = await store.dispatch('orders/getByIds', { orderIds: ordersInWork.map(order => order?.id) });
                    
                    if(ordersFromServer && ordersFromServer.length > 0) {
                        ordersInWork = ordersInWork.filter(order => {
                            for(const item of ordersFromServer) {
                                if(item.id === order.id) {
                                    return true;
                                }
                            }
                            return false;
                        });
                        await indexedDB.set('mircity-ordersInWork', ordersInWork);
                    } else {
                        await indexedDB.remove('mircity-ordersInWork');
                    }
                } catch (e) {}
                
                await this.checkOrdersInWork();
            },

            waitProcessing(val = false) {
                this.inProcess = !!val;
            },

            // ! Should be deleted after some weeks...
            async clearOldStorage() {
                const ordersInWork = await indexedDB.get('mircity-ordersInWork') || [];
                if (!ordersInWork?.length) {
                    return;
                }

                const clearedOrders = ordersInWork?.filter(order => order != null && order.constructor === Object);
                await indexedDB.set('mircity-ordersInWork', clearedOrders);
            },

            timeout(ms) {
                return new Promise(resolve => setTimeout(resolve, ms));
            },
        }
    };
</script>
