// Import Vue
import Vue from 'vue'
// import 'material-design-icons-iconfont'

// import VueFacebookPixel from 'vue-facebook-pixel'

// Import Firebase and Performance Monitoring
import firebase from 'firebase/app';
import 'firebase/performance';


const firebaseConfig = {
    apiKey: 'AIzaSyBpeCrocU_adzMzBKcigAgFIaFegTxvAOA',
    authDomain: 'mircity24-85083.firebaseapp.com',
    databaseURL: 'https://mircity24-85083-default-rtdb.asia-southeast1.firebasedatabase.app',
    projectId: 'mircity24-85083',
    storageBucket: 'mircity24-85083.appspot.com',
    messagingSenderId: '40651405859',
    appId: '1:40651405859:web:ac9bbec16d5a10137b4f96',
    measurementId: 'G-2CKGRJ7J7G'
};

try {
    if (!firebase?.apps?.length) {
        firebase.initializeApp(firebaseConfig);
    }
    // Activate Firebase Performance Monitoring
    const performance = firebase.performance();
} catch (e) {}


// Import Framework7
import Framework7 from 'framework7/framework7-lite.esm.bundle.js'

// Import Framework7-Vue Plugin
import Framework7Vue from 'framework7-vue/framework7-vue.esm.bundle.js'

//Import Bootstrap

// Import Styles
import '../css/main.css'
import '../css/tailwind.css'

// Import Framework7 Styles
import 'framework7/css/framework7.bundle.css'

// Import Icons and App Custom Styles
import '../css/app.scss'

// Import App Component
import App from '../components/app.vue'
import store from './../store'

import directivesMap from '../directives/index';
import vueResource from 'vue-resource';
import VueClipboard from 'vue-clipboard2'
// import vClickOutside from 'v-click-outside'


// Import plugins
import '../plugins/lazy'

// Init Framework7-Vue Plugin
Framework7.use(Framework7Vue)
// Vue.use(VueFacebookPixel)
// Vue.use(vClickOutside);

Vue.use(vueResource);
import VueYandexMetrika from 'vue-yandex-metrika'
if(process.env.VUE_APP_YANDEX_METRIKA_ID != '') {
    Vue.use(VueYandexMetrika, {
        id: process.env.VUE_APP_YANDEX_METRIKA_ID,
        env: 'production',
        options:  {
            clickmap: true,
            trackLinks: true,
            accurateTrackBounce: true,
            webvisor: true
        }
    })
}

for (const directiveName in directivesMap) {
    Vue.directive(directiveName, directivesMap[directiveName]);
}

// Init App
new Vue({
    el: '#app',
    store,

    // framework7: {
    //     root: '#app',
    //     // stackPages: false по умолчанию, но явно укажем:
    //     stackPages: false,
    //     // или domCache: false (в старых версиях)
    //     // domCache: false,
    //     // При необходимости добавьте routes, pushState и т.д.
    //   },

    // Register App Component
    components: {
        app: App
    },
    render: h => h(App),
})
