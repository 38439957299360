import axios from '../../plugins/axios';
import indexedDB from '../../plugins/indexedDB';
import { getServiceItemsByIds } from '@/api'
import { getMinMaxTimeString, calculateDeliveryPrice } from '@/js/utils/service-helpers'
import { CART_COMPARISON_KEYS, ORDER_TYPE, DELIVERY_TYPE } from '../../js/utils/vars'
import { getDeviceId, getUserId } from '../../js/utils/service-helpers';

export default {
    namespaced: true,
    state: {
        items: [],
        orderType: null,
        paymentType: null,
        cutlery: 1,
        demand: null,
        items_price: 0,
        old_items_price: 0,
        cart_price: 0,
        old_cart_price: 0,
        deliveryType: DELIVERY_TYPE.DOOR,
        deliveryPrice: 0,
        deliveryPriceByPrice: 0,
        serviceEntities: [],
        serviceSchedules: [],
        total_weight: 0,
        deliveryTime: 0,
        deliveryPromotion: 0,
        deliveryComment: '',
        promocode: '',
        promocode_price: 0,
        promocode_free_delivery: false
    },

    mutations: {
        SET_ITEMS(state, payload) {
            state.items = payload
        },
        CLEAR(state) {
            state.items = [];
            state.orderType = null;
            state.paymentType = null;
            state.cutlery = 1;
            state.items_price = 0;
            state.old_items_price = 0;
            state.cart_price = 0;
            state.old_cart_price = 0;
            state.deliveryType = DELIVERY_TYPE.DOOR;
            state.deliveryPrice = 0;
            state.deliveryPriceByPrice = 0;
            state.serviceEntities = [];
            state.serviceSchedules = [];
            state.total_weight = 0;
            state.deliveryPromotion = 0;
            state.deliveryComment = '';
            state.promocode = '';
            state.promocode_price = 0;
            state.promocode_free_delivery = false;
        },
        ADD_ITEM(state, payload) {
            state.items.push(payload);
        },
        RESTORE_ITEM(state, index) {
            if(state.items[index].quantity === 0) {
                state.items[index].quantity = state.items[index].weight_step || 1;
            }
            state.items[index].removed = false;
        },
        REMOVE_ITEM(state, { index, forced }) {
            if(state.items[index]) {
                if(forced || state.items[index].deleted || state.items[index].removed) {
                    state.items.splice(index, 1);
                } else {
                    state.items[index].removed = true;
                }
            }
        },
        SET_ITEM_QUANTITY(state, { quantity, index }) {
            if(quantity > 0) {
                state.items[index].removed = false;
            }
            state.items[index].quantity = quantity;
        },
        SET_ORDER_TYPE(state, payload) {
            state.orderType = payload
        },
        SET_PAYMENT_TYPE(state, payload) {
            state.paymentType = payload
        },
        SET_CUTLERY(state, payload) {
            state.cutlery = payload
        },
        SET_ITEMS_PRICE(state, payload) {
            state.items_price = Math.round(payload * 100) / 100;
        },
        SET_OLD_ITEMS_PRICE(state, payload) {
            state.old_items_price = Math.round(payload * 100) / 100;
        },
        SET_CART_PRICE(state, payload) {
            state.cart_price = Math.round(payload * 100) / 100;
        },
        SET_DELIVERY_TIME(state, payload) {
            state.deliveryTime = payload
        },
        SET_OLD_CART_PRICE(state, payload) {
            state.old_cart_price = Math.round(payload * 100) / 100;
        },
        SET_DELIVERY_TYPE(state, payload) {
            state.deliveryType = payload || DELIVERY_TYPE.DOOR;
        },
        SET_DELIVERY_SUM(state, payload) {
            state.deliveryPrice = payload
        },
        SET_DELIVERY_PRICE(state, payload) {
            state.deliveryPriceByPrice = payload
        },
        SET_DELIVERY_PROMOTION_PRICE(state, payload) {
            state.deliveryPromotion = payload;
        },
        SET_DELIVERY_COMMENT(state, payload) {
            state.deliveryComment = payload;
        },
        RECALC_CART_PRICE(state) {
            let cart_price = 0;
            let old_cart_price = 0;
            for (var i = 0; i < state.items.length; i++) {
                if(!state.items[i].deleted && !state.items[i].removed) {
                    cart_price += state.items[i].item_price * state.items[i].quantity;
                    old_cart_price += (state.items[i].old_item_price ? state.items[i].old_item_price : state.items[i].item_price) * state.items[i].quantity;
                }
            }
            state.cart_price = cart_price;
            state.old_cart_price = old_cart_price;
            state.items_price = cart_price;
            state.old_items_price = old_cart_price;
        },
        RECALC_TOTAL_WEIGHT(state) {
            var totalWeight = 0;
            if (state.items.length > 0) {
                for (let k = 0; k < state.items.length; k++) {
                    if (state.items[k].weight && !state.items[k].deleted && !state.items[k].removed) {
                        totalWeight += (+state.items[k].quantity * +state.items[k].weight);
                    }
                }
            }
            state.total_weight = totalWeight;
        },
        SET_DEMAND(state, payload) {
            state.demand = payload
        },
        SET_SERVICE_ENTITIES(state, payload) {
            state.serviceEntities = payload
        },
        SET_SERVICE_SCHEDULES(state, payload) {
            state.serviceSchedules = payload
        },
        SET_TOTAL_WEIGHT(state, payload) {
            state.total_weight = payload;
        },
        SET_PROMOCODE(state, payload) {
            state.promocode = payload;
        },
        SET_PROMOCODE_PRICE(state, payload) {
            state.promocode_price = payload;
        },
        SET_PROMOCODE_FREE_DELIVERY(state, payload) {
            state.promocode_free_delivery = payload;
        }
    },
    actions: {
        async clear({ commit, dispatch } = {}) {
            commit('CLEAR');
            await dispatch('setCacheData');
        },
        async setCacheData({ state }) {
            const device_id = await getDeviceId();
            const customer_id = await getUserId();
            if (device_id || customer_id) {
                await axios.post('/front/customer/cart', { 
                    device_id,
                    customer_id,
                    cart: JSON.stringify(state)
                });
                if(!device_id) {
                    await indexedDB.remove('mircity-cart');
                }
            } else {
                await indexedDB.set('mircity-cart', state);
            }
        },
        async getCacheData({ commit }) {
            let lsState = null;
            const device_id = await getDeviceId();
            const customer_id = await getUserId();
            if (customer_id) {
                const { data } = await axios.get(`/front/customer/cart/customer/${ customer_id }`);
                lsState = data.cart ? JSON.parse(data.cart) : null;
            } else if (device_id) {
                const { data } = await axios.get(`/front/customer/cart/device/${ device_id }`);
                lsState = data.cart ? JSON.parse(data.cart) : null;
            } else {
                lsState = await indexedDB.get('mircity-cart');
            }
            if (lsState) {
                lsState.items = lsState.items.filter(item => !item.removed);
                commit('SET_ORDER_TYPE', lsState.orderType);
                commit('SET_PAYMENT_TYPE', lsState.paymentType);
                commit('SET_ITEMS', lsState.items);
                commit('SET_CUTLERY', lsState.cutlery);
                commit('SET_ITEMS_PRICE', lsState.items_price);
                commit('SET_OLD_ITEMS_PRICE', lsState.old_items_price);
                commit('SET_CART_PRICE', lsState.cart_price);
                commit('SET_DELIVERY_TYPE', lsState.deliveryType);
                commit('SET_DELIVERY_SUM', lsState.deliveryPrice);
                commit('SET_DELIVERY_PRICE', lsState.deliveryPriceByPrice);
                commit('SET_OLD_CART_PRICE', lsState.old_cart_price);
                commit('SET_TOTAL_WEIGHT', lsState.total_weight);
                commit('SET_DELIVERY_PROMOTION_PRICE', lsState.deliveryPromotion);
                commit('SET_PROMOCODE', lsState.promocode);
                commit('SET_PROMOCODE_PRICE', lsState.promocode_price);
            }
            // commit('RECALC_CART_PRICE');
            commit('RECALC_TOTAL_WEIGHT');
        },
        setOrderType({ commit }, payload) {
            commit('SET_ORDER_TYPE', payload);
        },
        setPaymentType({ commit }, payload) {
            commit('SET_PAYMENT_TYPE', payload);
        },
        addItem({ state, commit, dispatch }, { item }) {
            let founded = false;
            for (var i = 0; i < state.items.length; i++) {
                if (_comparison(state.items[i], item)) {
                    const quantity = Math.round((+state.items[i].quantity + item.quantity) * 1000) / 1000;
                    commit('SET_ITEM_QUANTITY', { quantity, index: i })
                    founded = true;
                }
            }
            if (!founded) {
                commit('ADD_ITEM', item);
            }
            // commit('RECALC_CART_PRICE');
            commit('RECALC_TOTAL_WEIGHT');
        },
        restoreItem({ commit }, index) {
            commit('RESTORE_ITEM', index);
            // commit('RECALC_CART_PRICE');
            commit('RECALC_TOTAL_WEIGHT');
        },
        removeItem({ state, commit }, { index, forced = true }) {
            commit('REMOVE_ITEM', { index, forced });
            // commit('RECALC_CART_PRICE');
            commit('RECALC_TOTAL_WEIGHT');
            
            if (state.items.filter(item => !item.deleted && !item.removed).length === 0) {
                indexedDB.remove('mircity-pushNotificationShow');
                indexedDB.remove('mircity-updatePushNotificationDB');
            }
        },
        setItemQuantity({ commit }, { quantity, index }) {
            commit('SET_ITEM_QUANTITY', { quantity, index });
            // commit('RECALC_CART_PRICE');
            commit('RECALC_TOTAL_WEIGHT');
        },
        async setCutlery({ commit, dispatch }, { cutlery }) {
            commit('SET_CUTLERY', cutlery);
            await dispatch('setCacheData');
        },
        async getServiceEntities({ state, commit }, { serviceType }) {
            const serviceIds = getServiceIds(state.items, serviceType);
            const serviceEntities = await getServiceItemsByIds(serviceIds, serviceType);
            commit('SET_SERVICE_ENTITIES', serviceEntities);
        },
        async getServiceSchedules({ state, commit }) {
            const serviceIds = getServiceIds(state.items, state.orderType);
            const serviceSchedules = await getServiceItemsByIds(serviceIds, state.orderType, true);
            commit('SET_SERVICE_SCHEDULES', serviceSchedules);
        },
        async recalculateDeliveryPrice({ state, commit, dispatch }) {
            if (!state?.serviceEntities?.length) {
                return;
            }

            const deliveryMap = {};
            const promotionMap = {};
            let deliverySum = 0;
            let deliveryPrice = 0;
            let deliveryDiscount = 0;
            let deliveryDiscountSum = 0;
            let sumProductsForDelivery = 0;
            let withoutDiscOnDish = 0;

            commit('RECALC_CART_PRICE');
            
            for (let i = 0; i < state.serviceEntities.length; i++) {
                const entity = state.serviceEntities[i];
                if (!entity?.delivery?.id) {
                    continue;
                }
                deliveryMap[entity?.id] = entity?.delivery;

                if (!entity?.promotion?.id) {
                    continue;
                }
                promotionMap[entity?.id] = entity.promotion;
            }
            Object.values(promotionMap).forEach(item => {
                if (item.delivery_type && item.delivery_type === state.deliveryType && item.discount) {
                    deliveryDiscount = item.discount;
                }
            });

            for (const item of state.items) {
                if(!item.deleted && !item.removed) {
                    const itemPrice = item.item_price ? item.item_price : +item.price;
                    const itemOldPrice = item.old_item_price ? item.old_item_price : +item.old_price;
                    const newPrice = itemOldPrice - (itemOldPrice * deliveryDiscount / 100);
                    if(newPrice > itemPrice) {
                        deliveryDiscountSum += itemPrice * +item.quantity;
                    } else {
                        deliveryDiscountSum += newPrice * +item.quantity;
                    }
                    deliveryDiscountSum = Math.round(deliveryDiscountSum * 100) / 100;
                }
            }

            Object.values(deliveryMap).forEach(item => {
                const delivery = calculateDeliveryPrice({ delivery: item, orderPrice: state.cart_price, deliveryType: state.deliveryType });
                deliverySum += delivery.sum;
                deliveryPrice += delivery.price;
            });

            commit('SET_DELIVERY_PROMOTION_PRICE', deliveryDiscountSum);
            commit('SET_DELIVERY_PRICE', state.promocode_free_delivery ? 0 : deliveryPrice);
            commit('SET_DELIVERY_SUM', state.promocode_free_delivery ? 0 : deliverySum);
            await dispatch('setCacheData');
        },
        async recalculate({ state, commit, dispatch }) {
            await dispatch('getCacheData');
            let dishTmp = {};
            let item_price = 0;
            let old_item_price = 0;
            for (var i = 0; i < state.items.length; i++) {
                if (state.items[i].restaurant_id) {
                    dishTmp = await axios.get(`/front/restaurants/${state.items[i].restaurant_id}/dishes/${state.items[i].dish_id}`);
                    dishTmp = dishTmp.data;

                    item_price = +dishTmp.price;
                    old_item_price = +dishTmp.old_price;
                    for (let ii = 0; ii < dishTmp.modifiers.length; ii++) {
                        for (let ij = 0; ij < state.items[i].modifiers.length; ij++) {
                            if (dishTmp.modifiers[ii].id === state.items[i].modifiers[ij].id) {
                                state.items[i].modifiers[ij] = dishTmp.modifiers[ii];
                                item_price += +dishTmp.modifiers[ii].price
                                old_item_price += +dishTmp.modifiers[ii].old_price
                            }
                        }
                    }
                    for (let ii = 0; ii < dishTmp.ingredients.length; ii++) {
                        for (let ij = 0; ij < state.items[i].ingredients.length; ij++) {
                            if (dishTmp.ingredients[ii].id === state.items[i].ingredients[ij].id) {
                                state.items[i].ingredients[ij] = dishTmp.ingredients[ii];
                                item_price += +dishTmp.ingredients[ii].price
                                old_item_price += +dishTmp.ingredients[ii].old_price
                            }
                        }
                    }
                    if (dishTmp?.modifierGroups?.length) {
                        for (let i = 0; i < dishTmp.modifierGroups.length; i++) {
                            const group = dishTmp.modifierGroups[i];
                            for (let j = 0; j < group.modifiers.length; j++) {
                                if (group.modifiers[j].checked) {
                                    item_price += group.modifiers[j].price;
                                    old_item_price += group.modifiers[j].old_price;
                                }
                            }
                        }
                    }
                    if (dishTmp?.ingredientGroups?.length) {
                        for (let i = 0; i < dishTmp.ingredientGroups.length; i++) {
                            const group = dishTmp.ingredientGroups[i];
                            for (let j = 0; j < group.ingredients.length; j++) {
                                if (group.ingredients[j].checked) {
                                    item_price += group.ingredients[j].price;
                                    old_item_price += group.ingredients[j].old_price;
                                }
                            }
                        }
                    }
                    state.items[i] = {
                        ...state.items[i],
                        ...{
                            price: dishTmp.price,
                            old_price: dishTmp.old_price,
                            item_price: item_price,
                            old_item_price: old_item_price
                        }
                    }
                }
                if (state.items[i].entity_id) {
                    dishTmp = await axios.get(`/front/entities/${state.items[i].entity_id}/entity_products/${state.items[i].entity_product_id}`);
                    dishTmp = dishTmp.data;

                    item_price = +dishTmp.price;
                    old_item_price = +dishTmp.old_price;
                    for (let ii = 0; ii < dishTmp.entityVariants.length; ii++) {
                        for (let ij = 0; ij < state.items[i].entityVariants.length; ij++) {
                            if (dishTmp.entityVariants[ii].id === state.items[i].entityVariants[ij].id) {
                                state.items[i].entityVariants[ij] = dishTmp.entityVariants[ii];
                                item_price += +dishTmp.entityVariants[ii].price
                                old_item_price += +dishTmp.entityVariants[ii].old_price
                            }
                        }
                    }
                    for (let ii = 0; ii < dishTmp.entityOptions.length; ii++) {
                        for (let ij = 0; ij < state.items[i].entityOptions.length; ij++) {
                            if (dishTmp.entityOptions[ii].id === state.items[i].entityOptions[ij].id) {
                                state.items[i].entityOptions[ij] = dishTmp.entityOptions[ii];
                                item_price += +dishTmp.entityOptions[ii].price
                                old_item_price += +dishTmp.entityOptions[ii].old_price
                            }
                        }
                    }
                    state.items[i] = {
                        ...state.items[i],
                        ...{
                            price: dishTmp.price,
                            old_price: dishTmp.old_price,
                            item_price: item_price,
                            old_item_price: old_item_price
                        }
                    }
                }
            }
            commit('RECALC_CART_PRICE');
            commit('RECALC_TOTAL_WEIGHT');
            await dispatch('setCacheData');
        },

        setDemand({ state, commit }, { demand_id }) {
            const serviceEntities = state.serviceEntities;
            for(const service of serviceEntities) {
                service.delivery.price = service.delivery.all_prices.filter(item => item.demand_id === demand_id);
            }
            commit('SET_DEMAND', demand_id);
            commit('SET_SERVICE_ENTITIES', serviceEntities);
        },
    },
    getters: {
        count({ items }) {
            return items.filter(item => {
                return !item.deleted && !item.removed;
            }).length
        },
        quantity({ items }) {
            return items.filter(item => {
                return !item.deleted && !item.removed;
            }).reduce((sum, item) => {
                return sum + item.quantity
            }, 0)
        },
        isEmpty({ items }) {
            return items.filter(item => {
                return !item.deleted;
            }).length === 0
        },
        deliveryTime(state) {
            if (state?.serviceEntities?.length) {
                const service = state?.serviceEntities[0];
                const time = getMinMaxTimeString(
                    service.delivery_time,
                    service.delivery_time_hour,
                    service.max_delivery_time,
                    service.max_delivery_time_hour
                );

                if (time?.length > 14) {
                    return `до ${time.split('-')[1].trim()}`;
                }
                return time;
            }
            return state.deliveryTime;
        },
        totalWeight({ total_weight }) {
            return total_weight;
        },
    },
}

const getServiceIds = (serviceItems, serviceType) => {
    let ids = [];
    switch (serviceType) {
        case ORDER_TYPE.RESTAURANT_DELIVERY:
            ids = serviceItems.map(i => i.restaurant_id);
            break;
        case ORDER_TYPE.GROCER_DELIVERY:
            ids = serviceItems.map(i => i.grocer_id);
            break;
        case ORDER_TYPE.ENTITY_DELIVERY:
            ids = serviceItems.map(i => i.entity_id);
            break;
    }
    return ids;
}

const _comparison = (itemA, itemB) => {
    for (var key of CART_COMPARISON_KEYS) {
        if (itemA[key] || itemB[key]) {
            if (JSON.stringify(itemA[key]) != JSON.stringify(itemB[key])) {
                return false;
            }
        }
    }
    return true;
}
